import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { dobFormatter } from '../../../transforms';

const NOT_AVAILABLE = 'Not Available';

function ShowPatientDetails({ patient }) {
  return (
    <li
      key={patient.id}
      className="flex items-center justify-between gap-x-6 py-4"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="font-semibold text-black text-sm leading-6">
            {patient?.firstName} {patient?.lastName}
          </p>
        </div>
        <div className="mt-1 flex flex-col items-start gap-x-2 text-black/70 text-xs leading-5">
          <p className="whitespace-nowrap">
            DOB: {patient.dob ? dobFormatter(patient.dob) : NOT_AVAILABLE}
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Menu as="div" className="relative flex-none">
          <Menu.Button className="-m-2.5 block p-2.5 text-black/70 hover:text-black">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="size-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-black py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                <NavLink
                  to={`/customers/patients/${patient?.slug}`}
                  className="block px-3 py-1 text-sm text-white leading-6"
                >
                  View
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  to={`/customers/patients/${patient?.slug}/edit`}
                  className="block px-3 py-1 text-sm text-white leading-6"
                >
                  Edit
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </li>
  );
}

ShowPatientDetails.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default ShowPatientDetails;
