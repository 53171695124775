import { Input, PhoneInput } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';
import { initialValues, schema } from './helpers';

function SignIn() {
  const { login } = useAuth();

  return (
    <AuthFormContainer
      submitFunction={login}
      submitTitle="Sign In"
      links={[{ id: 1, to: '/forgot-password', text: 'Forgot your password?' }]}
      initialValues={initialValues()}
      schema={schema()}
      showLegal
    >
      <>
        <PhoneInput required name="phone" />

        <Input
          type="password"
          autoComplete="current-password"
          required
          name="password"
        />
      </>
    </AuthFormContainer>
  );
}

export default SignIn;
