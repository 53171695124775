import { useAccount, useAuth } from '@retainerclub/hooks';
import { useEffect } from 'react';
import DashboardLayoutTitle from '../../layouts/DashboardLayout/DashboardLayoutTitle';
import OrdersHistory from '../Orders/OrdersHistory/OrdersHistory';
import DashboardPatients from './DashboardPatients/DashboardPatients';

function Dashboard() {
  const { accountRefetch } = useAccount();
  const { currentUser } = useAuth();

  useEffect(() => {
    accountRefetch();
  }, [accountRefetch]);

  return (
    <>
      <DashboardLayoutTitle
        heading="Welcome back to Retainer Club"
        title={`${currentUser?.firstName} ${currentUser?.lastName}`}
        subtitle="How can we help you today?"
      />
      <DashboardPatients />
      <OrdersHistory limit />
    </>
  );
}

export default Dashboard;
