import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_PRICE_BOOK_PRODUCTS } from '@retainerclub/customer-api';
import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import ShopFilters from './ShopFilters';
import ShopProducts from './ShopProducts';

function Shop() {
  const { currentPatient } = useAccount();

  const [priceBookProducts, setPriceBookProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({ categories: [], types: [] });

  const { error, loading } = useQuery(GET_PRICE_BOOK_PRODUCTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { priceBookProducts: pBP } = data;

      const allRetainers = pBP.filter((p) =>
        p.product.productCategories.some((c) => c.name === 'Retainers'),
      );

      const retainers = allRetainers.filter((r) =>
        currentPatient.hasPlan
          ? r.product.defaultSubscriptionRetainer === true
          : r.product.defaultSubscriptionRetainer === false,
      );

      setPriceBookProducts([
        ...pBP.filter((p) => p.product.productType === 'plan'),
        ...retainers,
        ...pBP
          .filter(
            (p) =>
              p.product.productType !== 'plan' &&
              p.product.productCategories.some((c) => c.name !== 'Retainers'),
          )
          .sort((a, b) => a.product.name.localeCompare(b.product.name)),
      ]);
      setFilteredProducts([
        ...pBP.filter((p) => p.product.productType === 'plan'),
        ...retainers,
        ...pBP
          .filter(
            (p) =>
              p.product.productType !== 'plan' &&
              p.product.productCategories.some((c) => c.name !== 'Retainers'),
          )
          .sort((a, b) => a.product.name.localeCompare(b.product.name)),
      ]);
    },
  });

  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} />;
  }

  return (
    <div className="relative bg-white">
      <div className="absolute top-0 left-0 z-0 h-[250px] w-full overflow-hidden bg-cover bg-gradient-to-t bg-top from-rcprimary-200 to-rcprimary-500" />

      <main className="relative z-5 pb-24">
        <div className="px-4 py-16 text-center sm:px-6 lg:px-8">
          <h1 className="font-bold text-4xl text-gray-900 tracking-tight">
            Shop for {currentPatient?.firstName}
          </h1>
          <p className="mx-auto mt-4 max-w-xl text-base text-white">
            Shop the latest products offered by your provider
          </p>
        </div>

        <ShopFilters
          priceBookProducts={priceBookProducts}
          filters={filters}
          setFilters={setFilters}
          filteredProducts={filteredProducts}
          setFilteredProducts={setFilteredProducts}
        />

        <ShopProducts priceBookProducts={filteredProducts} />
      </main>
    </div>
  );
}

export default Shop;
