import { Button, RcLink } from '@retainerclub/common';

function CheckoutSuccessMessage() {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 rounded-lg border-2 border-rcprimary-400 p-8 ">
      <div>
        <h3 className="font-bold font-oswald text-black text-xl uppercase">
          Success
        </h3>
        <div className="mb-3 w-1/3 border-b-2 border-b-rcprimary-400 p-2" />
        <h2 className="font-bold text-5xl text-black">
          Thank you
          <br />
          for your order!
        </h2>
        <p className="mt-6">
          A confirmation email will be sent out soon. Check your&nbsp;
          <RcLink to="/">Dashboard</RcLink> or&nbsp;
          <RcLink to="/orders">Orders Tab</RcLink> for more details on your
          upcoming Retainer Club shipment
        </p>
        <Button name="Back to Dashboard" to="/" className="mt-8" size="lg" />
      </div>
    </div>
  );
}

export default CheckoutSuccessMessage;
