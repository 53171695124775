function OnboardingFormHeader() {
  return (
    <div className="z-10 mx-auto w-full max-w-full px-8 md:max-w-4xl">
      <h2 className="font-bold font-oswald text-black text-xl uppercase">
        Welcome to Retainer Club
      </h2>
      <div className="my-2 w-1/4 border-black border-b-4 py-1" />
      <h1 className="mt-4 font-bold text-5xl text-white">
        Simple Retainer
        <br />
        Replacement
      </h1>
      <p className="mt-4 font-normal text-black/90 text-lg leading-7 drop-shadow-2xl">
        You&apos;re one step closer to easy, online retainer replacement.
        <br />
        Create your Retainer Club account to get started today.
      </p>
    </div>
  );
}

export default OnboardingFormHeader;
