/* eslint-disable jsx-a11y/control-has-associated-label */
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function NavigationDesktopSearchButton({ setSearchOpen, searchOpen }) {
  return (
    <button
      type="button"
      className="flex max-w-xs items-center rounded-full text-black text-sm hover:text-rcprimary-400 focus:outline-none"
      onClick={() => setSearchOpen(!searchOpen)}
    >
      <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
}

NavigationDesktopSearchButton.propTypes = {
  setSearchOpen: PropTypes.func.isRequired,
  searchOpen: PropTypes.bool.isRequired,
};

export default NavigationDesktopSearchButton;
