/* eslint-disable no-console */
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_CART,
  DESTROY_CART,
  DESTROY_CART_ITEM,
  GET_CART,
  UPDATE_CART,
  UPDATE_CART_ITEM,
} from '@retainerclub/customer-api';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAccount } from './useAccount';
import { useAuth } from './useAuth';

const CartContext = createContext();

export function CartProvider() {
  const { currentUser } = useAuth();
  const { currentPatient } = useAccount();
  const cId = window.localStorage.getItem('rccart');
  const [cart, setCart] = useState({});

  const { data, refetch, loading, error } = useQuery(GET_CART, {
    variables: { id: cId },
    skip:
      cId === null ||
      cId === undefined ||
      currentUser === null ||
      currentUser === undefined,
  });

  const [createC] = useMutation(CREATE_CART);
  const [updateC] = useMutation(UPDATE_CART);
  const [destroyC] = useMutation(DESTROY_CART);

  const [updateCI] = useMutation(UPDATE_CART_ITEM);
  const [destroyCI] = useMutation(DESTROY_CART_ITEM);

  const updateCartLocal = useCallback((updatedCart) => {
    setCart(updatedCart);
    window.localStorage.setItem('rccart', updatedCart.id);
  }, []);

  const removeCart = useCallback(() => {
    setCart({});
    window.localStorage.removeItem('rccart');
  }, []);

  const createCart = useCallback(async () => {
    try {
      const resp = await createC({
        variables: {
          input: {
            input: {},
            clientMutationId: uuidv4(),
          },
        },
      });

      if (resp?.data?.createCart?.cart) {
        updateCartLocal(resp.data.createCart.cart);
      }
    } catch (err) {
      console.error('createCart error', err);
    }
  }, [createC, updateCartLocal]);

  const updateCart = useCallback(
    async (updatedCart) => {
      try {
        const resp = await updateC({
          variables: {
            input: {
              input: updatedCart,
              clientMutationId: uuidv4(),
            },
          },
        });

        if (resp?.data?.updateCart?.cart) {
          updateCartLocal(resp.data.updateCart.cart);
        }
      } catch (err) {
        console.error('updateCart error', err);
      }
    },
    [updateC, updateCartLocal],
  );

  const updateCartItem = useCallback(
    async (id, quantity) => {
      try {
        await updateCI({
          variables: {
            input: {
              input: {
                id,
                quantity,
                patientId: currentPatient?.id,
              },
              clientMutationId: uuidv4(),
            },
          },
        });
      } catch (err) {
        console.error('updateCartItem error', err);
      }
    },
    [currentPatient?.id, updateCI],
  );

  const destroyCart = useCallback(async () => {
    try {
      await destroyC({
        variables: {
          input: {
            id: cId,
            clientMutationId: uuidv4(),
          },
        },
      });

      removeCart();
    } catch (err) {
      console.error('destroyCart error', err);
    }
  }, [cId, destroyC, removeCart]);

  const destroyCartItem = useCallback(
    async (id) => {
      try {
        await destroyCI({
          variables: {
            input: {
              id,
              clientMutationId: uuidv4(),
            },
          },
        });

        refetch();
      } catch (err) {
        console.error('destroyCartItem error', err);
      }
    },
    [destroyCI, refetch],
  );

  useEffect(() => {
    if (data?.cart) {
      setCart(data.cart);
    }

    if (!cId && currentUser) {
      createCart();
    }
  }, [cId, createCart, currentUser, data?.cart]);

  const value = useMemo(
    () => ({
      cart,
      setCart,
      refetchCart: refetch,
      cartId: cId,
      updateCart,
      updateCartLocal,
      updateCartItem,
      removeCart,
      destroyCart,
      destroyCartItem,
      cLoading: loading,
      cError: error,
    }),
    [
      cart,
      cId,
      destroyCart,
      destroyCartItem,
      error,
      loading,
      refetch,
      updateCart,
      updateCartItem,
      removeCart,
      updateCartLocal,
    ],
  );

  return (
    <CartContext.Provider value={value}>
      <Outlet />
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
