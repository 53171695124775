import { Modal } from '@retainerclub/common';
import PropTypes from 'prop-types';
import RetainerWizardFromBank from './RetainerWizardFromBank/RetainerWizardFromBank';
import RetainerWizardNoBank from './RetainerWizardNoBank/RetainerWizardNoBank';

function RetainerWizardModal({
  open,
  handleCloseModal,
  currentPatient = null,
}) {
  return currentPatient ? (
    <Modal open={open} setOpen={handleCloseModal} size="5xl">
      <div className="p-0">
        {currentPatient.retainerBank > 0 &&
          currentPatient.hasPlan &&
          currentPatient.planStartAt !== null && (
            <RetainerWizardFromBank
              patient={currentPatient}
              handleCloseModal={handleCloseModal}
            />
          )}

        {currentPatient.retainerBank <= 0 &&
          currentPatient.hasPlan &&
          currentPatient.planStartAt !== null && (
            <RetainerWizardNoBank
              patient={currentPatient}
              handleCloseModal={handleCloseModal}
            />
          )}
      </div>
    </Modal>
  ) : null;
}

RetainerWizardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  currentPatient: PropTypes.object,
};

export default RetainerWizardModal;
