import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

function ChooseManyCheckbox({ setting, usePatientAttributes = false }) {
  const { patientAttributes, settingGroupOptionIds, settingsOverride } =
    useFormValues();
  const { setValue } = useFormContext();

  const fieldName = useMemo(
    () =>
      usePatientAttributes
        ? 'patientAttributes.settingGroupOptionIds'
        : 'settingGroupOptionIds',
    [usePatientAttributes],
  );

  const overrideFieldName = useMemo(
    () =>
      usePatientAttributes
        ? 'patientAttributes.settingsOverride'
        : 'settingsOverride',
    [usePatientAttributes],
  );

  const settingGroupOptionIdsValue = useMemo(
    () =>
      usePatientAttributes
        ? patientAttributes?.settingGroupOptionIds
        : settingGroupOptionIds,
    [
      usePatientAttributes,
      patientAttributes?.settingGroupOptionIds,
      settingGroupOptionIds,
    ],
  );

  function handleCheckboxChange(e) {
    const {
      target: { value, checked },
    } = e;

    if (checked) {
      const newValues = [...settingGroupOptionIdsValue, value];
      setValue(fieldName, newValues);
    } else {
      const filteredValues = settingGroupOptionIdsValue.filter(
        (id) => id !== value,
      );
      setValue(fieldName, filteredValues);
    }

    if (
      (!usePatientAttributes &&
        settingsOverride !== null &&
        settingsOverride !== undefined) ||
      (usePatientAttributes &&
        patientAttributes?.settingsOverride !== null &&
        patientAttributes?.settingsOverride !== undefined)
    ) {
      setValue(overrideFieldName, true);
    }
  }

  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          name={setting.id}
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={settingGroupOptionIdsValue.includes(setting.id)}
          value={setting.id}
          className="size-4 rounded border-gray-300 border-rcgray-600 text-rcprimary-400 focus:ring-rcprimary-400 disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700"
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="font-medium text-black/90">
          {setting.name}
        </label>

        <p className="text-black/70 text-xs">{setting.description}</p>
      </div>
    </div>
  );
}

ChooseManyCheckbox.propTypes = {
  setting: PropTypes.object.isRequired,
  usePatientAttributes: PropTypes.bool,
};

export default ChooseManyCheckbox;
