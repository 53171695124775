import { useMutation } from '@apollo/client';
import { ACTION_S3_SIGN, CREATE_SCAN_FILE } from '@retainerclub/shared-api';
import axios from 'axios';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

function FileDropzoneScan({
  patientId,
  account,
  arches = 'both',
  label = 'Drop a file here',
  refetch = () => null,
  fileType = 'default',
}) {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [signS3] = useMutation(ACTION_S3_SIGN);
  const [submitToServer] = useMutation(CREATE_SCAN_FILE);

  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }

  async function submitRecord(originalFilename, fileUrl) {
    try {
      await submitToServer({
        variables: {
          input: {
            input: {
              originalFilename,
              fileUrl,
              arches,
              patientId,
              accountId: account.id,
              fileType,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      refetch();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('submit record Error', err);
    }
  }

  async function uploadToS3(signedRequest, droppedFile) {
    setUploading(true);

    const options = {
      headers: {
        'Content-Type': droppedFile.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          setUploadProgress(100);
          setUploading(false);
        } else {
          setUploadProgress(percent);
        }
      },
    };

    await axios.put(signedRequest, droppedFile, options);
  }

  async function signAndSubmit(droppedFile) {
    const s3Key = `scans/${account.accountNumber}/${patientId}/${arches}/${formatFilename(droppedFile.name)}`;

    try {
      const response = await signS3({
        variables: {
          input: {
            input: {
              originalFilename: droppedFile.name,
              filename: s3Key,
              acl: 'private',
              filetype: droppedFile.type,
              assets: true,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      const { signedRequest, url } = response.data.actionSignS3.s3Response;

      await uploadToS3(signedRequest, droppedFile);

      await submitRecord(droppedFile.name, url);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('signAndSubmit Error', err);
    }
  }

  async function onDrop(accepted) {
    if (accepted && accepted.length > 0) {
      await Promise.all(accepted.map(async (file) => signAndSubmit(file)));
    }
  }

  const { isDragActive, isDragReject, getRootProps, getInputProps, open } =
    useDropzone({
      noClick: true,
      multiple: true,
      onDrop: (accepted) => onDrop(accepted),
      maxFiles: 10,
    });

  return (
    <div
      className={`relative mt-2 flex justify-center rounded-lg border border-dashed p-4 shadow-sm ${
        isDragActive
          ? 'border-rcprimary-400/25 bg-rcprimary-400/10'
          : 'border-black/40'
      } ${isDragReject ? 'bg-red-50' : ''}`}
      {...getRootProps()}
    >
      <div className="w-full text-center">
        <div className="mt-1 flex flex-col text-gray-600 text-sm leading-6">
          <label
            htmlFor="scanUploader"
            className="relative cursor-pointer rounded-md bg-white font-semibold text-rcprimary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-rcprimary-400 focus-within:ring-offset-2 hover:text-rcprimary-400"
          >
            <input {...getInputProps()} />
          </label>
          <p className={`${isDragReject && isDragActive && 'text-red-400'}`}>
            {!uploading && isDragActive && !isDragReject && 'Drop file here...'}
            {!uploading && isDragReject && 'File type not accepted, sorry!'}
            {!uploading && !isDragActive && (
              <span className="cursor-pointer text-black/70">
                <span className="font-medium">{label}</span>
                <br />
                <button
                  type="button"
                  onClick={open}
                  className="text-rcprimary-400 text-sm hover:text-rcprimary-500"
                >
                  click here to select
                </button>
              </span>
            )}
          </p>
          {uploading && (
            <div className="w-full">
              <progress
                value={uploadProgress}
                max="100"
                className="h-1.5 w-full"
              >
                {uploadProgress}
              </progress>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FileDropzoneScan.propTypes = {
  arches: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  label: PropTypes.string,
  refetch: PropTypes.func,
  fileType: PropTypes.string,
};

export default FileDropzoneScan;
