import { AddressAutofill } from '@mapbox/search-js-react';
import { CountrySelect, Input, RegionSelect } from '@retainerclub/common';
import { useFormContext } from 'react-hook-form';

function OnboardingFormAddress() {
  const { setValue } = useFormContext();

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
      <div className="sm:col-span-6">
        <AddressAutofill
          accessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          confirmOnBrowserAutofill={{
            minimap: false,
            skipConfirmModal: (feature) =>
              ['exact', 'high'].includes(
                feature.properties.match_code.confidence,
              ),
          }}
          onRetrieve={(address) => {
            if (address?.features?.length > 0) {
              const {
                country_code: countryCode,
                region_code: regionCode,
                matching_name: matchingName,
              } = address.features[0].properties;

              setValue(
                'accountAddressesAttributes[0].country',
                countryCode.toUpperCase(),
              );

              setValue(
                'accountAddressesAttributes[0].region',
                regionCode.toUpperCase(),
              );

              setValue('accountAddressesAttributes[0].address', matchingName);
            }
          }}
        >
          <Input
            name="accountAddressesAttributes[0].address"
            label="Address"
            required
            autoComplete=""
          />
        </AddressAutofill>
      </div>
      <div className="translate-y-4 text-red-700 text-xs sm:col-span-6">
        Please add Unit, Suite, or Apt number to avoid shipping delays.
      </div>

      <div className="sm:col-span-3">
        <Input
          name="accountAddressesAttributes[0].address2"
          label="Address Line 2"
          autoComplete="address-line2"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          name="accountAddressesAttributes[0].city"
          label="City"
          required
          autoComplete="address-level2"
        />
      </div>
      <div className="sm:col-span-2">
        <CountrySelect
          name="accountAddressesAttributes[0].country"
          required
          autoComplete=""
        />
      </div>
      <div className="sm:col-span-2">
        <RegionSelect
          name="accountAddressesAttributes[0].region"
          countryFieldName="accountAddressesAttributes[0].country"
          autoComplete=""
        />
      </div>
      <div className="sm:col-span-2">
        <Input
          name="accountAddressesAttributes[0].postalCode"
          label="Postal Code"
          required
          autoComplete="postal-code"
        />
      </div>
    </div>
  );
}

export default OnboardingFormAddress;
