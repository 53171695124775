import { Outlet } from 'react-router-dom';
import DashboardPartner from '../../views/Dashboard/DashboardPartner';

function DashboardLayout() {
  return (
    <div className="relative mb-24">
      <div className="fixed z-0 h-[500px] w-full overflow-hidden bg-cover bg-gradient-to-t bg-top from-rcprimary-200 to-rcprimary-500" />

      <div className="relative col-span-full mx-auto flex max-w-5xl flex-col gap-y-4 pt-4">
        <Outlet />
        <DashboardPartner />
      </div>
    </div>
  );
}

export default DashboardLayout;
