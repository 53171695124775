import PropTypes from 'prop-types';

function GeneralSectionItem(props) {
  const { name, details } = props;

  return (
    <div className="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="mt-0 font-medium text-black text-sm leading-6">{name}</dt>
      <dd className="mt-0 text-black/70 text-sm capitalize leading-6 sm:col-span-2">
        {details}
      </dd>
    </div>
  );
}

GeneralSectionItem.propTypes = {
  name: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};

export default GeneralSectionItem;
