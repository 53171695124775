import PropTypes from 'prop-types';

function Card({
  children,
  className = '',
  heading = null,
  wrapperClassName = '',
  headingSize = 'xl',
  customBg = null,
}) {
  return (
    <section
      aria-labelledby="applicant-information-title"
      className={wrapperClassName}
    >
      <div
        className={`rounded-md ${customBg || 'bg-white/80'} shadow-sm ${className}`}
      >
        {heading && (
          <h2
            className={`${headingSize === 'xl' && 'text-xl'} ${
              headingSize === 'lg' && 'text-lg'
            } font-bold text-black/80 leading-6`}
          >
            {heading}
          </h2>
        )}
        {children}
      </div>
    </section>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  heading: PropTypes.string,
  wrapperClassName: PropTypes.string,
  headingSize: PropTypes.string,
  customBg: PropTypes.string,
};

export default Card;
