import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import FieldContainer from '../FieldContainer/FieldContainer';

function PhoneField({
  name = 'phone',
  label = 'phone number',
  required = false,
  style = {},
  className = '',
  hideLabel = false,
  autoFocus = false,
  extraCountries = [], // for new altiamcx users. so we can add Countries only to Admin forms and Profile
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
    >
      <PhoneInputWithCountry
        name={name}
        control={control}
        rules={{ required }}
        type="tel"
        autoComplete="tel"
        autoFocus={autoFocus}
        defaultCountry="US"
        className="w-full"
        countries={[
          ...['US', 'CA', 'GB', 'DE', 'FR', 'IT', 'ES', 'AU', 'MX'],
          ...extraCountries,
        ]}
        countryOptionsOrder={['US', 'CA', 'AU', '|', '...']}
        addInternationalOption={false}
        withCountryCallingCode
        countryCallingCodeEditable={false}
        numberInputProps={{
          className: `block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
            errors?.[name]
              ? 'ring-red-300 placeholder:text-red-300 focus:ring-red-500 text-red-900'
              : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
          }`,
        }}
      />
    </FieldContainer>
  );
}

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  extraCountries: PropTypes.array,
};

export default PhoneField;
