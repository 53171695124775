import { ListBulletIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../transforms';
import SelectMenu from '../../SelectMenu/SelectMenu';
import SelectMenuItem from '../../SelectMenu/SelectMenuItem';
import ShowStatus from '../ShowStatus/ShowStatus';

function ShowHeader({
  actions = null,
  title,
  name,
  state = null,
  active = null,
  editTo = null,
  listTo = null,
  showActions = true,
  upcaseHeader = false,
  imageUrl = null,
  imageAlt = null,
  datePlaced = null,
  total = null,
}) {
  return (
    <header className="relative isolate z-10">
      <div className="mx-auto rounded-lg bg-white/90 p-4 shadow-sm">
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-between gap-x-8 sm:flex-row lg:mx-0 lg:max-w-none">
          <div className="flex items-center gap-x-8">
            {imageUrl && (
              <div className="shrink-0">
                <img
                  src={imageUrl}
                  alt={imageAlt}
                  className="size-16 object-contain object-center"
                />
              </div>
            )}

            <div>
              <p className="text-center font-bold text-black/60 text-sm leading-6 sm:text-left">
                {title}
              </p>
              <h1
                className={`text-xl ${
                  upcaseHeader ? 'uppercase' : ''
                } text-black`}
              >
                {name}
              </h1>
            </div>
          </div>

          {datePlaced && (
            <div>
              <p className="text-center font-bold text-black/60 text-sm leading-6 sm:text-left">
                Date Placed
              </p>
              <h3 className="text-black text-xl">
                {timestampFormatter(datePlaced)}
              </h3>
            </div>
          )}

          {total !== null && total !== undefined && (
            <div>
              <p className="text-center font-bold text-black/60 text-sm leading-6 sm:text-left">
                Total
              </p>
              <h3 className="text-black text-xl">
                ${(total / 100).toFixed(2)}
              </h3>
            </div>
          )}

          <div className="mt-4 flex items-center gap-x-4 sm:mt-0 sm:gap-x-6">
            {(state || active) && <ShowStatus state={state} active={active} />}
            {showActions && (
              <SelectMenu>
                <div className="py-1">
                  {editTo && (
                    <SelectMenuItem to={editTo} icon={PencilSquareIcon}>
                      Edit
                    </SelectMenuItem>
                  )}
                  {listTo && (
                    <SelectMenuItem to={listTo} icon={ListBulletIcon}>
                      Back To List
                    </SelectMenuItem>
                  )}
                </div>

                {actions}
              </SelectMenu>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

ShowHeader.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string,
  active: PropTypes.bool,
  editTo: PropTypes.string,
  listTo: PropTypes.string,
  showActions: PropTypes.bool,
  upcaseHeader: PropTypes.bool,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  datePlaced: PropTypes.string,
  total: PropTypes.number,
};

export default ShowHeader;
