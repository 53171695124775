import { BaseLoader } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function RequireAuth() {
  const { currentUser, authLoading } = useAuth();
  const location = useLocation();

  if (authLoading) {
    return <BaseLoader />;
  }

  if (currentUser) {
    return <Outlet />;
  }

  return <Navigate to="/signin" state={{ from: location }} replace />;
}

export default RequireAuth;
