function defaultValues(props) {
  const { phone } = props;

  let e = '';

  if (phone) {
    e = phone;
  }

  return {
    token: '',
    password: '',
    passwordConfirm: '',
    phone: e,
  };
}

export default defaultValues;
