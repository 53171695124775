const languageOptions = {
  en: [
    {
      text: 'English',
      flag: 'us',
      value: 'en',
      label: 'English',
    },
    {
      text: 'French',
      flag: 'fr',
      value: 'fr',
      label: 'French',
    },
    {
      text: 'German',
      flag: 'de',
      value: 'de',
      label: 'German',
    },
    {
      text: 'Italian',
      flag: 'it',
      value: 'it',
      label: 'Italian',
    },
    {
      text: 'Spanish',
      flag: 'es',
      value: 'es',
      label: 'Spanish',
    },
    {
      text: 'Japanese',
      flag: 'ja',
      value: 'ja',
      label: 'Japanese',
    },
  ],
  fr: [
    {
      text: 'Anglais',
      flag: 'us',
      value: 'en',
      label: 'Anglais',
    },
    {
      text: 'Française',
      flag: 'fr',
      value: 'fr',
      label: 'Française',
    },
    {
      text: 'Allemande',
      flag: 'de',
      value: 'de',
      label: 'Allemande',
    },
    {
      text: 'Italienne',
      flag: 'it',
      value: 'it',
      label: 'Italienne',
    },
    {
      text: 'Espanol',
      flag: 'es',
      value: 'es',
      label: 'Espanol',
    },
    {
      text: 'Japonais',
      flag: 'ja',
      value: 'ja',
      label: 'Japonais',
    },
  ],
  de: [
    {
      text: 'Englisch',
      flag: 'us',
      value: 'en',
      label: 'Englisch',
    },
    {
      text: 'Französisch',
      flag: 'fr',
      value: 'fr',
      label: 'Französisch',
    },
    {
      text: 'Deutsche',
      flag: 'de',
      value: 'de',
      label: 'Deutsche',
    },
    {
      text: 'Italienisch',
      flag: 'it',
      value: 'it',
      label: 'Italienisch',
    },
    {
      text: 'Spanisch',
      flag: 'es',
      value: 'es',
      label: 'Spanisch',
    },
    {
      text: 'Japanisch',
      flag: 'ja',
      value: 'ja',
      label: 'Japanisch',
    },
  ],
  it: [
    {
      text: 'Inglese',
      flag: 'us',
      value: 'en',
      label: 'Inglese',
    },
    {
      text: 'Francese',
      flag: 'fr',
      value: 'fr',
      label: 'Francese',
    },
    {
      text: 'Tedesco',
      flag: 'de',
      value: 'de',
      label: 'Tedesco',
    },
    {
      text: 'Italiano',
      flag: 'it',
      value: 'it',
      label: 'Italiano',
    },
    {
      text: 'Spagnolo',
      flag: 'es',
      value: 'es',
      label: 'Spagnolo',
    },
    {
      text: 'Niapponese',
      flag: 'ja',
      value: 'ja',
      label: 'Niapponese',
    },
  ],
  es: [
    {
      text: 'Inglés',
      flag: 'us',
      value: 'en',
      label: 'Inglés',
    },
    {
      text: 'Francés',
      flag: 'fr',
      value: 'fr',
      label: 'Francés',
    },
    {
      text: 'Alemán',
      flag: 'de',
      value: 'de',
      label: 'Alemán',
    },
    {
      text: 'Italiano',
      flag: 'it',
      value: 'it',
      label: 'Italiano',
    },
    {
      text: 'Español',
      flag: 'es',
      value: 'es',
      label: 'Español',
    },
    {
      text: 'Japonés',
      flag: 'ja',
      value: 'ja',
      label: 'Japonés',
    },
  ],
  ja: [
    {
      text: '英語',
      flag: 'us',
      value: 'en',
      label: '英語',
    },
    {
      text: 'フランス語',
      flag: 'fr',
      value: 'fr',
      label: 'フランス語',
    },
    {
      text: 'ドイツ語',
      flag: 'de',
      value: 'de',
      label: 'ドイツ語',
    },
    {
      text: 'イタリアの',
      flag: 'it',
      value: 'it',
      label: 'イタリアの',
    },
    {
      text: 'スペイン語',
      flag: 'es',
      value: 'es',
      label: 'スペイン語',
    },
    {
      text: '日本',
      flag: 'ja',
      value: 'ja',
      label: '日本',
    },
  ],
};

export default languageOptions;
