import DashboardLayoutTitle from '../../layouts/DashboardLayout/DashboardLayoutTitle';
import FamilyPatients from './FamilyPatients/FamilyPatients';
import FamilyUsers from './FamilyUsers/FamilyUsers';

function Family() {
  return (
    <>
      <DashboardLayoutTitle
        heading="Patients & Account Holders"
        title="My Family"
        subtitle="Manage or add family members to access your account or receive treatment."
      />
      <FamilyPatients />
      <FamilyUsers />
    </>
  );
}

export default Family;
