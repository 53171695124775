import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  ShoppingBagIcon,
} from '@heroicons/react/20/solid';
import { Button, dobFormatter, timestampFormatter } from '@retainerclub/common';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function DashboardPatientCard({
  patient,
  handleShop,
  handleOpenModal,
  handleOpenRetainerWizard,
}) {
  const scheduledShipments = useMemo(
    () =>
      patient.scheduledShipments
        .sort(
          (a, b) =>
            new Date(b.expectedShippedAt) - new Date(a.expectedShippedAt),
        )
        .filter((shipment) => !shipment.consumed),
    [patient.scheduledShipments],
  );

  return (
    <div className="basis-1 sm:basis-1/2 md:basis-1/3">
      <div className="m-2 flex h-full flex-col divide-y divide-gray-300 rounded-lg border bg-rcgray-50 text-center shadow-lg">
        <div className="flex flex-1 flex-col p-8">
          <img
            className="mx-auto size-16 shrink-0 rounded-full"
            src="https://cdn.retainerclub.com/images/defaults/default-user.jpeg"
            alt=""
          />
          <h3 className="mt-4 font-bold text-2xl">{patient.firstName}</h3>
          <p className="text-sm">{dobFormatter(patient.dob)}</p>
          <div className="mt-3">
            <span
              className={`inline-flex items-center gap-2 rounded-full ${patient.hasPlan ? 'bg-green-50 text-green-700 ring-green-600/20' : 'bg-yellow-50 text-yellow-800 ring-yellow-600/20'} px-2 py-1 font-medium text-xs ring-1 ring-inset `}
            >
              {patient.hasPlan ? (
                <CheckCircleIcon className="size-4" />
              ) : (
                <ExclamationTriangleIcon className="size-4" />
              )}
              {patient.hasPlan ? 'Smile Care Plan' : 'No Current Plan'}
            </span>
          </div>
          <div className="mt-3 rounded-lg border border-rcgray-300 bg-rcgray-100 p-2 text-black/65">
            <p className="text-sm leading-4">
              <span className="">Retainer Bank</span>

              <br />

              <span className="font-bold text-base">
                {patient.retainerBank > 0 ? patient.retainerBank : 'none'}&nbsp;
                available
              </span>
              <br />
              {scheduledShipments.length > 0 ? (
                <span className="text-xs">
                  (next expected to ship&nbsp;
                  {timestampFormatter(
                    scheduledShipments[0].expectedShippedAt,
                    false,
                  )}
                  )
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </p>
            <Button
              className="mt-2 rounded-xl"
              onClick={() =>
                patient.hasPlan && patient.planStartAt !== null
                  ? handleOpenRetainerWizard(patient)
                  : handleShop(patient)
              }
              name={
                patient.hasPlan && patient.planStartAt !== null
                  ? 'Need A Retainer Now?'
                  : `Shop for ${patient.firstName}`
              }
              size="sm"
            />
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-300">
            <div className="flex w-0 flex-1">
              <button
                type="button"
                onClick={() => handleShop(patient)}
                className="-mr-px relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 font-semibold text-gray-900 text-sm"
              >
                <ShoppingBagIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
                Shop
              </button>
            </div>
            <div className="-ml-px flex w-0 flex-1">
              <button
                type="button"
                onClick={() => handleOpenModal(patient)}
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 font-semibold text-gray-900 text-sm"
              >
                <PencilIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardPatientCard.propTypes = {
  patient: PropTypes.object.isRequired,
  handleShop: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleOpenRetainerWizard: PropTypes.func.isRequired,
};

export default DashboardPatientCard;
