function initialValues(props) {
  const { record = {}, accountId } = props;

  return {
    id: record?.id || null,
    address: record?.address || null,
    address2: record?.address2 || null,
    city: record?.city || null,
    region: record?.region || null,
    postalCode: record?.postalCode || null,
    country: record?.country || 'US',
    accountId: record?.accountId || accountId,
    isShipping:
      record?.isShipping !== null && record?.isShipping !== undefined
        ? record?.isShipping
        : true,
    isBilling:
      record?.isBilling !== null && record?.isBilling !== undefined
        ? record?.isBilling
        : false,
    isDefault:
      record?.isDefault !== null && record?.isDefault !== undefined
        ? record?.isDefault
        : false,
  };
}

export default initialValues;
