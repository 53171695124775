import CheckoutFormAddress from './CheckoutFormAddress';
import CheckoutFormDelivery from './CheckoutFormDelivery';
import CheckoutFormPayment from './CheckoutFormPayment';

function CheckoutForm() {
  return (
    <div className="flex flex-col gap-y-2">
      <CheckoutFormPayment />
      <CheckoutFormAddress />
      <CheckoutFormDelivery />
    </div>
  );
}

export default CheckoutForm;
