/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Button, dobFormatter, timestampFormatter } from '@retainerclub/common';
import { CustomerCard } from '@retainerclub/customer-shared';
import { useAccount } from '@retainerclub/hooks';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FamilyPatientModal from './FamilyPatientModal';

function FamilyPatients() {
  const { patients, accountRefetch, setCurrentPatient } = useAccount();
  const [currentModalPatient, setCurrentModalPatient] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleShop(patient) {
    setCurrentPatient(patient);
    navigate('/shop');
  }

  function handleOpenModal(p) {
    setCurrentModalPatient(p);
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
    setCurrentModalPatient(null);

    accountRefetch();
  }

  return (
    <>
      <CustomerCard
        title="Patients"
        action={
          <Button
            onClick={() => handleOpenModal(null)}
            name="New Patient"
            add
          />
        }
      >
        <ul className="divide-y divide-gray-100">
          {patients.map((patient) => (
            <li
              key={patient.id}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {patient.firstName} {patient.lastName}
                  </p>
                  <p className="mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                    {patient.hasPlan ? 'Smile Care Plan' : 'No Plan'}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="whitespace-nowrap">
                    {dobFormatter(patient.dob)}
                  </p>
                  <svg viewBox="0 0 2 2" className="size-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="truncate">
                    Joined {timestampFormatter(patient.createdAt, false)}
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  type="button"
                  onClick={() => handleShop(patient)}
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                  Shop
                </button>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <EllipsisVerticalIcon
                      className="size-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => handleOpenModal(patient)}
                            className={`${active ? 'bg-gray-50' : ''} block px-3 py-1 text-sm leading-6 text-gray-900`}
                          >
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
      </CustomerCard>
      <FamilyPatientModal
        open={open}
        handleCloseModal={() => handleCloseModal()}
        currentPatient={currentModalPatient}
      />
    </>
  );
}

export default FamilyPatients;
