import { useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import OrderHistoryTrackingInfo from './OrderHistoryTrackingInfo';

function OrdersHistoryOrderItem({ orderItem, productionOrders = null }) {
  const { locale } = useLocale();

  return (
    <div key={orderItem.id} className="flex py-6 sm:py-10">
      <div className="min-w-0 flex-1 lg:flex lg:flex-col">
        <div className="lg:flex-1">
          <div className="gap-4 sm:flex">
            <div className="grow">
              <h4 className="font-medium text-gray-900">{orderItem.name}</h4>
              <p className="mt-2 hidden text-gray-500 text-sm sm:block">
                {orderItem.product.description}
                {orderItem.patient ? (
                  <>
                    <br />
                    <span className="text-xs">
                      Purchased For:&nbsp;
                      <span className="font-bold">
                        {orderItem.patient.firstName}
                      </span>
                    </span>
                  </>
                ) : null}
              </p>
            </div>
            <p className="mt-1 font-medium text-gray-900 sm:mr-8">
              {(orderItem.total / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </p>
          </div>

          <OrderHistoryTrackingInfo productionOrders={productionOrders} />
          {/* <div className="mt-2 flex text-sm font-medium sm:mt-4">
            <RcLink to={`/shop/${orderItem.slug}`}>View Product</RcLink>
          </div> */}
        </div>
      </div>
      <div className="ml-4 shrink-0 sm:order-first sm:m-0 sm:mr-6">
        <img
          src={orderItem.product.featuredImage}
          alt={orderItem.name}
          className="col-start-2 col-end-3 size-20 rounded-lg object-cover object-center sm:col-start-1 sm:row-span-2 sm:row-start-1 sm:size-40 lg:size-52"
        />
      </div>
    </div>
  );
}

OrdersHistoryOrderItem.propTypes = {
  orderItem: PropTypes.object.isRequired,
  productionOrders: PropTypes.array,
};

export default OrdersHistoryOrderItem;
