import * as Yup from 'yup';

function schema() {
  return Yup.object({
    currentPassword: Yup.string().min(8, 'Too short!').required('Required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
  });
}

export default schema;
