import { Dialog } from '@headlessui/react';
import { EmailInput, Input, Modal, PhoneInput } from '@retainerclub/common';
import PropTypes from 'prop-types';
import FamilyUsersModalWrapper from './FamilyUsersModalWrapper';

function FamilyUsersModal({ open, handleCloseModal }) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl">
      <div>
        <div className="text-center">
          <Dialog.Title
            as="h2"
            className="text-rcgray-900 text-2xl font-semibold capitalize leading-6"
          >
            Add a New account holder
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-4">
        <FamilyUsersModalWrapper handleCloseModal={handleCloseModal}>
          <div className="sm:col-span-6">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
              <Input
                className="sm:col-span-3"
                required
                name="firstName"
                label="User First Name"
              />
              <Input
                className="sm:col-span-3"
                required
                name="lastName"
                label="User Last Name"
              />

              <EmailInput
                className="sm:col-span-3"
                required
                label="Account Holder Email"
              />

              <PhoneInput
                className="sm:col-span-3"
                required
                name="phone"
                label="Account Holder Phone"
              />
            </div>
          </div>
        </FamilyUsersModalWrapper>
      </div>
    </Modal>
  );
}

FamilyUsersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default FamilyUsersModal;
