import { Transition } from '@headlessui/react';
import {
  CheckboxInput,
  LoadingOrError,
  SubmitButton,
} from '@retainerclub/common';
import { usePolicy } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import OnboardingFormAccount from './OnboardingFormAccount/OnboardingFormAccount';
import OnboardingFormAddress from './OnboardingFormAddress/OnboardingFormAddress';
import OnboardingFormErrors from './OnboardingFormErrors';
import OnboardingFormHeader from './OnboardingFormHeader';
import OnboardingFormPatient from './OnboardingFormPatient/OnboardingFormPatient';
import OnboardingFormStep from './OnboardingFormStep';

function OnboardingFormInner({
  mutationErrors = null,
  error = null,
  pError = null,
  rError = null,
  loading,
}) {
  const [show, setShow] = useState(false);
  const { setPolicyAndOpenModal } = usePolicy();

  const {
    formState: { isLoading, isSubmitting, isValid },
  } = useFormContext();

  const masterLoading = useMemo(
    () => loading || isLoading || isSubmitting,
    [isLoading, isSubmitting, loading],
  );

  return (
    <div className="flex flex-col gap-y-4 sm:gap-y-6">
      <OnboardingFormHeader />
      <div className="relative z-10 mx-auto flex w-full max-w-full flex-col gap-y-8 bg-rcgray-50 p-8 md:max-w-4xl lg:rounded-lg lg:border lg:shadow-2xl">
        <div>
          <p className="font-medium text-base">
            Already have a Retainer Club Account?&nbsp;
            <NavLink
              to="/signin"
              className="font-bold text-rcprimary-400 underline hover:text-black"
            >
              Sign In Here
            </NavLink>
          </p>
        </div>
        {masterLoading || rError || pError || error ? (
          <div className="absolute top-0 left-0 h-[400px] w-full bg-gray-50 sm:rounded-lg sm:shadow-2xl">
            <LoadingOrError
              loading={masterLoading}
              error={error}
              className="h-[400px] w-full"
            />
          </div>
        ) : (
          <>
            <OnboardingFormStep
              step={1}
              show
              title="Patient Information"
              description="Verify the person receiving retainers and let us help you get
          the perfect fit."
            >
              <OnboardingFormPatient setShow={setShow} show={show} />
            </OnboardingFormStep>

            <OnboardingFormStep
              step={2}
              title="Account Holder Details"
              description="Let's create your Retainer Club account to get started today."
              show={show}
            >
              <OnboardingFormAccount setShow={setShow} show={show} />
            </OnboardingFormStep>
            <OnboardingFormStep
              step={3}
              title="Shipping Address"
              description="Where can we send your retainers? Make sure to add your apartment
          number if you have one."
              show={show}
            >
              <OnboardingFormAddress setShow={setShow} show={show} />
            </OnboardingFormStep>
            <Transition
              show={show}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="flex flex-col gap-2 border-t pt-4"
            >
              <CheckboxInput
                name="userAttributes.acceptTerms"
                label="Terms and Conditions"
                description={
                  <span>
                    I agree to the Retainer Club&apos;s&nbsp;
                    <button
                      type="button"
                      className="text-rcprimary-400 hover:text-rcprimary-300"
                      onClick={() => setPolicyAndOpenModal('terms')}
                    >
                      Terms and Conditions
                    </button>
                    &nbsp; and&nbsp;
                    <button
                      type="button"
                      className="text-rcprimary-400 hover:text-rcprimary-300"
                      onClick={() => setPolicyAndOpenModal('privacy')}
                    >
                      Privacy Policy
                    </button>
                    . I understand that I am responsible for the accuracy of the
                    information provided and that I am at least 18 years old.
                  </span>
                }
              />
              <CheckboxInput
                name="userAttributes.acceptSms"
                label="SMS Updates"
                description="I agree to receive SMS notifications and updates about my account from Retainer Club. Message and data rates may apply. Reply STOP to opt out."
              />
              <CheckboxInput
                name="userAttributes.acceptMarketing"
                label="Marketing Notifications"
                description="I agree to receive marketing emails and SMS notifications from Retainer Club."
              />
            </Transition>
          </>
        )}

        <OnboardingFormErrors mutationErrors={mutationErrors} error={error} />

        {!masterLoading && (
          <Transition
            show={show}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <SubmitButton
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                isValid={isValid}
                label="Let's Get Started!"
                full
                notRounded
              />
            </div>
          </Transition>
        )}
      </div>
    </div>
  );
}

OnboardingFormInner.propTypes = {
  mutationErrors: PropTypes.object,
  error: PropTypes.object,
  pError: PropTypes.object,
  rError: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default OnboardingFormInner;
