function trackingUrl(carrier, trackingNumber) {
  if (
    carrier === null ||
    carrier === undefined ||
    trackingNumber === null ||
    trackingNumber === undefined
  ) {
    return '';
  }

  const carrierName = carrier?.toLowerCase();

  if (carrierName === 'ups') {
    return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
  }

  if (carrierName === 'fedex') {
    return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
  }

  if (carrierName === 'usps') {
    return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
  }

  return '';
}

export default trackingUrl;
