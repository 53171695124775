import { useQuery } from '@apollo/client';
import {
  BuildingOffice2Icon,
  GlobeAltIcon,
  MapIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { LoadingOrError, RcLink } from '@retainerclub/common';
import { GET_PARTNER_LOC } from '@retainerclub/customer-api';
import { CustomerCard } from '@retainerclub/customer-shared';
import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

function DashboardPartner() {
  const [location, setLocation] = useState(null);
  const { partnerLocationId } = useAccount();

  const { error, loading } = useQuery(GET_PARTNER_LOC, {
    variables: {
      id: partnerLocationId,
    },
    fetchPolicy: 'cache-and-network',
    skip: partnerLocationId === null || partnerLocationId === undefined,
    onCompleted: (data) => {
      setLocation(data.partnerLocation);
    },
  });

  return partnerLocationId ? (
    <CustomerCard title="My Provider">
      {(loading || error) && location === null ? (
        <LoadingOrError loading={loading} error={error} />
      ) : (
        <div className="mt-4 flex flex-col items-center gap-8 md:flex-row">
          {location?.imageUrl && (
            <div className="size-32">
              <img
                className="mx-auto object-cover"
                src={location?.imageUrl}
                alt={location?.name}
              />
            </div>
          )}

          <dl className="grid grow grid-cols-1 gap-4 text-gray-600 text-sm leading-5 md:grid-cols-2">
            <div className="flex gap-x-4 md:col-span-1">
              <dt className="flex-none">
                <BuildingOffice2Icon
                  className="size-6 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd className="font-medium text-base">{location?.name}</dd>
            </div>
            <div className="flex gap-x-4 md:col-span-1">
              <dt className="flex-none">
                <MapIcon className="size-6 text-gray-400" aria-hidden="true" />
              </dt>
              <dd>
                {location?.address?.address}
                <br />
                {location?.address?.address2 && (
                  <>
                    {location?.address?.address2}
                    <br />
                  </>
                )}
                {location?.address?.city}, {location?.address?.region}&nbsp;
                {location?.address?.postalCode}
              </dd>
            </div>
            {location?.phone && (
              <div className="flex gap-x-4 md:col-span-1">
                <dt className="flex-none">
                  <PhoneIcon
                    className="size-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <RcLink href={`tel:${location?.phone}`}>
                    {formatPhoneNumber(location?.phone)}
                  </RcLink>
                </dd>
              </div>
            )}
            {location?.website && (
              <div className="flex gap-x-4 md:col-span-1">
                <dt className="flex-none">
                  <GlobeAltIcon
                    className="size-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <RcLink href={location?.website}>{location?.website}</RcLink>
                </dd>
              </div>
            )}
          </dl>
        </div>
      )}
    </CustomerCard>
  ) : null;
}

export default DashboardPartner;
