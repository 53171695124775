import PropTypes from 'prop-types';

function ShowDetailsSection({
  title,
  children,
  className = '',
  horizontal = true,
}) {
  return (
    <div
      className={`${
        horizontal
          ? 'py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'
          : 'sm:col-span-1 sm:px-0'
      } ${className}`}
    >
      <dt className="font-bold text-black/60 text-sm leading-4">{title}</dt>
      <dd
        className={`text-black/90 text-sm ${
          horizontal
            ? 'break-all pr-1 leading-4 sm:col-span-2'
            : 'mt-1 leading-4'
        }`}
      >
        {children}
      </dd>
    </div>
  );
}

ShowDetailsSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
};

export default ShowDetailsSection;
