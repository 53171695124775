/* eslint-disable jsx-a11y/label-has-associated-control */
import { AddressAutofill } from '@mapbox/search-js-react';
import {
  CountrySelect,
  FieldContainer,
  Input,
  RegionSelect,
} from '@retainerclub/common';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import CheckoutFormSection from './CheckoutFormSection';

function CheckoutFormPayment() {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <CheckoutFormSection title="Payment" first>
        <Input
          className="sm:col-span-6"
          required
          name="creditCard.nameOnCard"
          label="Cardholder Name"
          autoComplete="cc-name"
        />
        <Controller
          name="creditCard.cardNumber"
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <FieldContainer
              className="sm:col-span-6"
              required
              name="creditCard.cardNumber"
              label="Card Number"
            >
              <PatternFormat
                format="#### #### #### ####"
                name={name}
                getInputRef={ref}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                autoComplete="cc-number"
                valueIsNumericString
                className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700 sm:text-sm sm:leading-6 ${
                  errors?.[name]
                    ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500'
                    : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
                }`}
              />
            </FieldContainer>
          )}
        />

        <Controller
          name="creditCard.expirationDate"
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <FieldContainer
              className="sm:col-span-2"
              required
              name="creditCard.expirationDate"
              label="Exp. Date"
            >
              <PatternFormat
                format="##/##"
                name={name}
                getInputRef={ref}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                valueIsNumericString
                allowEmptyFormatting
                mask="_"
                autoComplete="cc-exp"
                className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700 sm:text-sm sm:leading-6 ${
                  errors?.[name]
                    ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500'
                    : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
                }`}
              />
            </FieldContainer>
          )}
        />

        <Input
          className="sm:col-span-2"
          required
          name="creditCard.cvv"
          label="CVV"
          autoComplete="csc"
          placeholder="123"
          attributes={{ maxLength: 4 }}
        />
      </CheckoutFormSection>
      <CheckoutFormSection title="Billing Address">
        <div className="sm:col-span-6">
          <AddressAutofill
            accessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
            confirmOnBrowserAutofill={{
              minimap: true,
              skipConfirmModal: (feature) =>
                ['exact', 'high'].includes(
                  feature.properties.match_code.confidence,
                ),
            }}
            onRetrieve={(address) => {
              if (address?.features?.length > 0) {
                const {
                  country_code: countryCode,
                  region_code: regionCode,
                  matching_name: matchingName,
                } = address.features[0].properties;

                setValue('billingAddress.country', countryCode.toUpperCase());

                setValue('billingAddress.region', regionCode.toUpperCase());

                setValue('billingAddress.address', matchingName);
              }
            }}
          >
            <Input
              name="billingAddress.address"
              label="Address"
              required
              autoComplete=""
            />
          </AddressAutofill>
        </div>

        <div className="sm:col-span-3">
          <Input
            name="billingAddress.address2"
            label="Address Line 2"
            autoComplete="address-line2"
          />
        </div>
        <div className="sm:col-span-3">
          <Input
            name="billingAddress.city"
            label="City"
            required
            autoComplete="address-level2"
          />
        </div>
        <div className="sm:col-span-2">
          <CountrySelect
            name="billingAddress.country"
            required
            autoComplete=""
          />
        </div>
        <div className="sm:col-span-2">
          <RegionSelect
            name="billingAddress.region"
            countryFieldName="billingAddress.country"
            autoComplete=""
          />
        </div>
        <div className="sm:col-span-2">
          <Input
            name="billingAddress.postalCode"
            label="Postal Code"
            required
            autoComplete="postal-code"
          />
        </div>
      </CheckoutFormSection>
    </>
  );
}

export default CheckoutFormPayment;
