import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

function ChooseOneRadio({
  setting,
  settingGroup,
  usePatientAttributes = false,
}) {
  const { settingGroupOptionIds, settingsOverride, patientAttributes } =
    useFormValues();
  const { setValue } = useFormContext();

  const fieldName = useMemo(
    () =>
      usePatientAttributes
        ? 'patientAttributes.settingGroupOptionIds'
        : 'settingGroupOptionIds',
    [usePatientAttributes],
  );

  const overrideFieldName = useMemo(
    () =>
      usePatientAttributes
        ? 'patientAttributes.settingsOverride'
        : 'settingsOverride',
    [usePatientAttributes],
  );

  const settingGroupOptionIdsValue = useMemo(
    () =>
      usePatientAttributes
        ? patientAttributes?.settingGroupOptionIds
        : settingGroupOptionIds,
    [
      usePatientAttributes,
      patientAttributes?.settingGroupOptionIds,
      settingGroupOptionIds,
    ],
  );

  const settingGroupOthers = useMemo(
    () =>
      settingGroup.settingGroupOptions
        .filter((option) => option.id !== setting.id)
        .map((option) => option.id),
    [setting.id, settingGroup.settingGroupOptions],
  );

  function handleCheckboxChange(e) {
    const {
      target: { value },
    } = e;

    const filterForOthers = settingGroupOptionIdsValue.filter(
      (id) => !settingGroupOthers.includes(id),
    );

    const newValues = [...filterForOthers, value];

    setValue(fieldName, newValues);

    if (
      (!usePatientAttributes &&
        settingsOverride !== null &&
        settingsOverride !== undefined) ||
      (usePatientAttributes &&
        patientAttributes?.settingsOverride !== null &&
        patientAttributes?.settingsOverride !== undefined)
    ) {
      setValue(overrideFieldName, true);
    }
  }

  return (
    <div key={setting.id} className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          aria-describedby={`${setting.id}-description`}
          name={settingGroup.id}
          type="radio"
          onChange={handleCheckboxChange}
          checked={settingGroupOptionIdsValue.includes(setting.id)}
          value={setting.id}
          className="size-4 border-rcgray-600 text-rcprimary-400 focus:ring-rcprimary-400"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={setting.id} className="font-medium text-black/90">
          {setting.name}
        </label>
        <p id={`${setting.id}-description`} className="text-black/70 text-xs">
          {setting.description}
        </p>
      </div>
    </div>
  );
}

ChooseOneRadio.propTypes = {
  setting: PropTypes.object.isRequired,
  settingGroup: PropTypes.object.isRequired,
  usePatientAttributes: PropTypes.bool,
};

export default ChooseOneRadio;
