import { PhotoIcon } from '@heroicons/react/24/outline';
import { useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Badge from '../../Badge/Badge';
import { Button } from '../../Button';
import ProductShowDetails from './ProductShowDetails/ProductShowDetails';
import ProductShowGeneralSection from './ProductShowGeneralSection/ProductShowGeneralSection';
import ProductShowImages from './ProductShowImages';

function ProductShow(props) {
  const { product } = props;

  const { locale } = useLocale();

  return (
    <div className="rounded-lg bg-white/80 shadow-md">
      <div className="mx-auto max-w-2xl p-4 lg:max-w-full">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          <ProductShowImages
            featuredImage={product.featuredImage}
            images={product.productImages}
          />

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="font-bold text-3xl text-black tracking-tight">
              {product.name}
            </h1>

            <div className="mt-2">
              <p className="mb-2 text-3xl text-black tracking-tight">
                {(product.price / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </p>
              <Badge name={product.productType} color="yellow" />
            </div>

            <ProductShowGeneralSection product={product} />

            <div className="mt-4">
              <div className="space-y-4 text-base text-black/80">
                {product.description}
              </div>
            </div>

            {product.productFeaturesAttributes.length > 0 ? (
              <ul className="ml-8 list-disc text-black/80">
                {product.productFeaturesAttributes.map((feature) => (
                  <li key={feature.id}>{feature.text}</li>
                ))}
              </ul>
            ) : null}

            <div className="mt-6">
              <div className="flex">
                <Button
                  to={`/shop/products/${product.slug}/edit`}
                  edit
                  name="Edit Product"
                />

                <NavLink
                  to={`/shop/products/${product.slug}/images`}
                  className="ml-4 flex items-center justify-center rounded-md bg-rcgray-300 p-3 text-rcgray-700 hover:bg-rcgray-400 hover:text-black"
                >
                  <PhotoIcon className="size-6 shrink-0" aria-hidden="true" />
                  <span className="sr-only">Edit Images</span>
                </NavLink>
              </div>
            </div>

            <section aria-labelledby="details-heading" className="mt-8">
              <ProductShowDetails product={product} />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductShow.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductShow;
