import * as Yup from 'yup';

function schema() {
  return Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
    passwordConfirm: Yup.string()
      .required('Please confirm your password')
      .when('password', {
        is: (password) => password && password.length > 0,
        then: (s) => s.oneOf([Yup.ref('password')], "Password doesn't match"),
      }),
  });
}

export default schema;
