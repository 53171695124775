import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { get, useFormContext } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import FieldContainer from '../FieldContainer/FieldContainer';

function EmailInput({
  name = 'email',
  label = 'Email',
  style = {},
  className = '',
  placeholder = 'user@email.com',
  required = false,
  hideLabel = false,
  inputClass = {},
  disabled = false,
  attributes = {},
  autoComplete = null,
}) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const values = useFormValues();

  const downCaseEmail = () => {
    const parsedVal = get(values, name);

    setValue(name, parsedVal?.toLowerCase());
  };

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      type="email"
      disabled={disabled}
    >
      <input
        {...register(name)}
        {...attributes}
        name={name}
        type="email"
        inputMode="email"
        required={required}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={downCaseEmail}
        autoComplete={autoComplete}
        className={`block w-full rounded-md border-0 py-1.5 pl-10 lowercase shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700 sm:text-sm sm:leading-6 ${
          errors?.[name]
            ? 'text-red-900 ring-red-500 placeholder:text-red-500 focus:ring-red-500 '
            : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400 '
        } ${inputClass}`}
      />
    </FieldContainer>
  );
}

EmailInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  attributes: PropTypes.object,
  autoComplete: PropTypes.string,
};

export default EmailInput;
