import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function NavigationDesktopLink(props) {
  const { to, title } = props;

  const { t } = useTranslation('navigation');

  return (
    <NavLink
      to={to}
      end
      className={({ isActive }) =>
        `${isActive ? 'bg-rcprimary-400 text-black' : 'text-black'}hover:text-rcprimary-400 rounded-md p-2 font-medium text-sm outline outline-2 outline-white/0 outline-offset-0 hover:bg-rcprimary-400/0 hover:outline-rcprimary-400 `
      }
    >
      {t(title)}
    </NavLink>
  );
}

NavigationDesktopLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavigationDesktopLink;
