import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormErrors } from '../FieldContainer';

function CheckboxInput({
  name,
  label = null,
  description = null,
  required = false,
  disabled = false,
  className = '',
  size = 'md',
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`relative flex gap-x-3 ${className}`}>
      <div className="flex h-6 items-center">
        <input
          {...register(name)}
          name={name}
          type="checkbox"
          disabled={disabled}
          className={`text-rcprimary-400 focus:ring-rcprimary-400 disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700 ${
            size === 'md' && 'size-4'
          } ${
            size === 'lg' && 'size-5'
          } rounded border-gray-300 border-rcgray-600 disabled:cursor-not-allowed`}
        />
      </div>
      <div className="text-sm leading-6">
        <label
          htmlFor={name}
          className={`font-medium text-black/90 ${size === 'lg' && 'text-md'}`}
        >
          {label || name}
          {required && <span className="text-red-400"> *</span>}
        </label>
        {description && (
          <p className="mt-1 text-black/60 text-xs">{description}</p>
        )}
        <FormErrors name={name} errors={errors} />
      </div>
    </div>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default CheckboxInput;
