import PropTypes from 'prop-types';

function DashboardLayoutTitle({ heading, title, subtitle = null }) {
  return (
    <div className="z-10 mx-auto w-full max-w-full px-4 md:max-w-5xl">
      <h2 className="font-bold font-oswald text-black text-xl uppercase">
        {heading}
      </h2>
      <div className="my-2 w-1/4 border-black border-b-4 py-1" />
      <h1 className="mt-2 font-bold text-5xl text-white">{title}</h1>
      {subtitle && (
        <p className="mt-2 font-normal text-lg text-white/90 leading-7 drop-shadow-2xl">
          {subtitle}
        </p>
      )}
    </div>
  );
}

DashboardLayoutTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default DashboardLayoutTitle;
