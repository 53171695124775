import PropTypes from 'prop-types';
import GeneralSectionItem from './GeneralSectionItem';

const NONE_SELECTED = 'None Selected';

function ProductShowGeneralSection(props) {
  const { product } = props;
  return (
    <div className="mt-2">
      <dl className="divide-y divide-rcgray-400">
        <GeneralSectionItem
          name="Internal Name"
          details={product.internalName}
        />
        <GeneralSectionItem name="SKU" details={product.sku} />
        <GeneralSectionItem
          name="Categories"
          details={
            product.productCategories.length > 0
              ? product.productCategories.map((cat) => cat.name).join()
              : NONE_SELECTED
          }
        />
      </dl>
    </div>
  );
}

ProductShowGeneralSection.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductShowGeneralSection;
