import { format, parseISO } from 'date-fns';

function timestampFormatter(date, showTime = true) {
  let stringFormat = 'MM/dd/yy';

  if (showTime) {
    stringFormat += ' h:mmaaa';
  }

  if (date === null || date === undefined) {
    return null;
  }

  return format(parseISO(date), stringFormat);
}

export default timestampFormatter;
