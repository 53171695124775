// response codes

const responseCodes = {
  GW00: {
    status: 'success',
    code: '00',
    message:
      'The transaction has been accepted and approved by the host processor',
  },
  GW01: {
    status: 'pending',
    code: '01',
    message: 'Call issuer for authorization',
  },
  GW02: {
    status: 'declined',
    code: '02',
    message:
      'The transaction has been accepted but declined by the host processor',
  },
  GW03: {
    status: 'declined',
    code: '03',
    message:
      'Request formatting error. Typically this error is received when missing required parameter values. The transaction was not passed to the host processor.',
  },
  GW04: {
    status: 'declined',
    code: '04',
    message: 'Confiscate card (no fraud assumed)',
  },
  GW05: { status: 'declined', code: '05', message: 'Do not honor card' },
  GW06: {
    status: 'declined',
    code: '06',
    message:
      'A duplicate Qor-Idempotent-Key header value was detected. The transaction was not passed to the host processor.',
  },
  GW07: {
    status: 'declined',
    code: '07',
    message:
      'The card passed for a level 3 transaction is not qualified. The transaction was not passed to the host processor.',
  },
  GW08: {
    status: 'pending',
    code: '08',
    message: 'Valid ID required for transaction',
  },
  GW09: {
    status: 'declined',
    code: '09',
    message: 'Non-specified Error. You can try the transaction again',
  },
  GW10: {
    status: 'declined',
    code: '10',
    message: 'Approved for lesser amount',
  },
  GW11: { status: 'declined', code: '11', message: 'Invalid Account format' },
  GW12: {
    status: 'declined',
    code: '12',
    message: 'Transaction type not permitted for this account',
  },
  GW13: {
    status: 'declined',
    code: '13',
    message: 'Invalid transaction amount',
  },
  GW14: {
    status: 'declined',
    code: '14',
    message: 'Account number or length error.',
  },
  GW15: { status: 'declined', code: '15', message: 'Invalid issuer.' },
  GW17: {
    status: 'declined',
    code: '17',
    message: 'Cardholder requested a cancellation on recurring charges',
  },
  GW18: { status: 'declined', code: '18', message: 'Customer dispute' },
  GW19: {
    status: 'declined',
    code: '19',
    message: 'Bad transaction data or setup, re-enter',
  },
  GW20: {
    status: 'declined',
    code: '20',
    message: 'Void no longer possible, issue a refund',
  },
  GW21: {
    status: 'declined',
    code: '21',
    message: 'Invalid refund transaction, issue a void',
  },
  GW22: { status: 'declined', code: '22', message: 'Violation' },
  GW24: { status: 'declined', code: '24', message: 'PIN tries exceeded' },
  GW30: {
    status: 'declined',
    code: '30',
    message: 'Date error. Check format or value is a future date',
  },
  GW31: {
    status: 'declined',
    code: '31',
    message: 'Card type not accepted by host / switch',
  },
  GW34: {
    status: 'declined',
    code: '34',
    message: 'Confiscate card (fraud assumed)',
  },
  GW39: {
    status: 'declined',
    code: '39',
    message: 'No such credit account',
  },
  GW40: {
    status: 'declined',
    code: '40',
    message: 'Requested function not supported',
  },
  GW41: {
    status: 'declined',
    code: '41',
    message: 'Confiscate card (reported lost)',
  },
  GW43: {
    status: 'declined',
    code: '43',
    message: 'Confiscate card (reported stolen)',
  },
  GW44: {
    status: 'declined',
    code: '44',
    message: 'Try lesser amount',
  },
  GW51: {
    status: 'declined',
    code: '51',
    message: 'Insufficient funds',
  },
  GW54: {
    status: 'declined',
    code: '54',
    message: 'Credit/Debit card expired',
  },
  GW55: {
    status: 'declined',
    code: '55',
    message: 'Bad Debit/ EBT pin info',
  },
  GW56: {
    status: 'declined',
    code: '56',
    message: 'Invalid Address',
  },
  GW57: {
    status: 'declined',
    code: '57',
    message: 'Card not permitted for this transaction type',
  },
  GW60: {
    status: 'declined',
    code: '60',
    message: 'Invalid Zip/Postal code',
  },
  GW61: {
    status: 'declined',
    code: '61',
    message: 'Exceeds withdrawal limit',
  },
  GW62: {
    status: 'declined',
    code: '62',
    message: 'Invalid service code',
  },
  GW63: {
    status: 'declined',
    code: '63',
    message: 'Security violation',
  },
  GW65: {
    status: 'declined',
    code: '56',
    message: 'Exceeds activity limit',
  },
  GW70: {
    status: 'declined',
    code: '70',
    message: 'Terminal does not support card type',
  },
  GW71: {
    status: 'declined',
    code: '71',
    message: 'Invalid Terminal Id',
  },
  GW72: {
    status: 'declined',
    code: '72',
    message: 'Account closed',
  },
  GW73: {
    status: 'declined',
    code: '73',
    message: 'Capture already processed',
  },
  GW74: {
    status: 'declined',
    code: '74',
    message: 'Refund / Void already processed',
  },
  GW75: {
    status: 'declined',
    code: '75',
    message: 'Transaction aborted',
  },
  GW76: {
    status: 'declined',
    code: '76',
    message: 'Record not found',
  },
  GW77: {
    status: 'declined',
    code: '77',
    message: 'Terminal / Device error',
  },
  GW81: {
    status: 'declined',
    code: '81',
    message: 'Encryption Error (usually debit/ebt)',
  },
  GW82: {
    status: 'declined',
    code: '82',
    message: 'CVV incorrect',
  },
  GW84: {
    status: 'declined',
    code: '84',
    message: '3DS data incorrect',
  },
  GW89: {
    status: 'declined',
    code: '89',
    message: 'Bad merchant ID',
  },
  GW94: {
    status: 'declined',
    code: '94',
    message: 'Duplicate batch number',
  },
  GW95: {
    status: 'declined',
    code: '95',
    message: 'Batch error',
  },
  GW96: {
    status: 'declined',
    code: '96',
    message: 'System error',
  },
  GW97: {
    status: 'declined',
    code: '97',
    message: 'CVV2 / CID error',
  },
  GW99: {
    status: 'declined',
    code: '99',
    message: 'Void not accepted',
  },
  n3: {
    status: 'declined',
    code: 'N3',
    message: 'Cash back services unavailable (VISA)',
  },
  n4: {
    status: 'declined',
    code: 'N4',
    message: 'Too much cash back (VISA)',
  },
  n7: {
    status: 'declined',
    code: 'N7',
    message: 'CVV2 mismatch (VISA)',
  },
  GW3003: {
    status: 'declined',
    code: '3003',
    message: 'Transaction amount over maximum allowed',
  },
  GW3004: {
    status: 'declined',
    code: '3004',
    message: 'Transaction amount under minimum allowed',
  },
};

export default responseCodes;
