/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@retainerclub/hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { profileNavigation } from '../helpers/navLinks';

const ACTIVE_TEXT_COLOR = 'text-rcprimary-400';
const DEFAULT_TEXT_COLOR = 'text-white';
const LINK_CLASSES =
  'group flex w-full items-center justify-start gap-x-2.5 px-4 py-2 text-left text-sm';
const ICON_CLASSES =
  'group-hover:text-rcprimary-400 h-5 w-5 flex-none text-white/60';

function DesktopNavProfile() {
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation('navigation');

  return currentUser ? (
    <Menu as="div" className="relative shrink-0">
      <div>
        <Menu.Button className="hover:text-rcprimary-400 flex max-w-xs items-center rounded-xl text-sm text-black focus:outline-none">
          {currentUser.imageUrl !== null &&
          currentUser.imageUrl !== undefined &&
          currentUser.imageUrl !== '' ? (
            <img
              className="mr-2 inline-block size-8 rounded-full"
              src={currentUser.imageUrl}
              alt="Profile"
            />
          ) : (
            <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="border-rcprimary-100/30 absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md border bg-black/90 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
          {profileNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <NavLink
                  to={item.to}
                  className={`${
                    active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
                  } ${LINK_CLASSES}`}
                >
                  {item.icon && (
                    <item.icon className={ICON_CLASSES} aria-hidden="true" />
                  )}
                  {t(item.name)}
                </NavLink>
              )}
            </Menu.Item>
          ))}

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={signOut}
                className={`${
                  active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
                } ${LINK_CLASSES}`}
              >
                <LockClosedIcon className={ICON_CLASSES} aria-hidden="true" />
                {t('Sign Out')}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : (
    <NavLink
      to="/signin"
      className="flex items-center text-sm font-semibold leading-6 text-gray-900"
    >
      <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
      <span className="text-rcprimary-400 text-base font-normal">Sign In</span>
    </NavLink>
  );
}

export default DesktopNavProfile;
