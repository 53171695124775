import { useApolloClient } from '@apollo/client';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, UserIcon } from '@heroicons/react/20/solid';
import {
  FaceFrownIcon,
  GlobeAmericasIcon,
  IdentificationIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { GET_ADMIN_SEARCH } from '@retainerclub/admin-api';
import { useApp } from '@retainerclub/hooks';
import { GET_PARTNER_SEARCH } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LOCS = 'Partner locations';
const ORGS = 'Partner organizations';

function Search(props) {
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const { t } = useTranslation('navigation');
  const client = useApolloClient();

  const [searchState, setSearchState] = useState({
    results: [],
    value: '',
  });

  const { results, value } = searchState;

  const { isAdmin, isPartner } = useApp();

  useEffect(() => {
    async function performQuery() {
      let query = GET_ADMIN_SEARCH;
      if (isPartner) {
        query = GET_PARTNER_SEARCH;
      }

      const { data } = await client.query({
        query,
        variables: { q: value },
        fetchPolicy: 'no-cache',
      });

      const formattedData = data.search
        .map((search) => {
          const { attributes } = search;
          return attributes;
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setSearchState((prevState) => ({
        ...prevState,
        results: formattedData,
      }));
    }

    const timer = setTimeout(() => {
      if (value.length === 0) {
        return setSearchState({
          results: [],
          value: '',
        });
      }

      return performQuery();
    }, 500);

    return () => clearTimeout(timer);
  }, [client, isPartner, value]);

  const filteredItems = value === '' ? [] : results;

  const groups = filteredItems.reduce((g, item) => {
    if (!g[item.klass]) {
      g[item.klass] = [];
    }
    g[item.klass].push(item);
    return g;
  }, {});

  async function handleSearchChange(val) {
    setSearchState((prevState) => ({
      ...prevState,
      value: val,
    }));
  }

  function resetSearch() {
    setSearchState((prevState) => ({
      ...prevState,
      value: '',
    }));
  }

  function handleResultSelect(result) {
    setOpen(false);

    switch (result.klass) {
      case 'Accounts':
        navigate(`/customers/accounts/${result.slug}`);
        break;
      case LOCS:
        if (isAdmin) {
          navigate(`/partners/locations/${result.slug}`);
        } else if (isPartner) {
          navigate(`/locations/${result.slug}`);
        }
        break;
      case ORGS:
        if (isAdmin) {
          navigate(`/partners/organizations/${result.slug}`);
        }
        break;
      case 'Patients':
        navigate(`customers/patients/${result.slug}`);
        break;
      case 'Products':
        if (isAdmin) {
          navigate(`/shop/products/${result.slug}`);
        }
        break;
      case 'Users':
        navigate(`/customers/users/${result.slug}`);
        break;
      default:
        // Handle any unexpected cases
        break;
    }
  }

  function iconColor(klass) {
    if (klass === 'Accounts') {
      return 'bg-rcprimary-400';
    }

    if (klass === 'Patients') {
      return 'bg-rcprimary-700';
    }

    if (klass === 'Users') {
      return 'bg-rcprimary-600';
    }

    return '';
  }

  function getDescription(item) {
    if (item.klass === LOCS || item.klass === ORGS) {
      return (
        <span>
          {item.city}, {item.region}
        </span>
      );
    }

    if (item.klass === 'Patients' || item.klass === 'Users') {
      return `Acct: ${item.accountNumber}`;
    }

    if (item.klass === 'Accounts') {
      return `${item?.partnerLocation?.name} | ${item.users
        .map((user) => user.name)
        .join(', ')}`;
    }

    return '';
  }

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => resetSearch()}
      appear
    >
      <Dialog as="div" className="relative z-[70]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl overflow-hidden rounded-xl bg-black shadow-2xl ring-1 ring-white/20 transition-all">
              <Combobox onChange={(item) => handleResultSelect(item)}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-rcgray-200"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pr-4 pl-11 text-rcgray-200 placeholder:text-rcgray-400 focus:ring-0 sm:text-sm"
                    placeholder={`${t('Search')}...`}
                    onChange={(event) => handleSearchChange(event.target.value)}
                  />
                </div>

                {value === '' && (
                  <div className="border-white/20 border-t px-6 py-14 text-center text-sm sm:px-14">
                    <GlobeAmericasIcon
                      className="mx-auto h-6 w-6 text-rcgray-500"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-rcgray-200">
                      {t('Global Search')}
                    </p>
                    <p className="mt-2 text-rcgray-500">
                      {t('Quickly access info by running a search.')}
                    </p>
                  </div>
                )}

                {filteredItems.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-96 scroll-py-3 overflow-y-auto p-3"
                  >
                    {Object.entries(groups).map(([category, res]) => (
                      <li key={category}>
                        <h2 className="bg-rcgray-950 px-4 py-2.5 font-semibold text-rcprimary-200 text-xs capitalize">
                          {category}
                        </h2>
                        <ul className="mt-2 text-sm text-white/90">
                          {res.map((item) => (
                            <Combobox.Option
                              key={item.id}
                              value={item}
                              className={({ active }) =>
                                `flex cursor-pointer select-none rounded-xl p-3 ${
                                  active && 'bg-rcgray-950/50 text-black'
                                }`
                              }
                            >
                              {({ active }) => (
                                <>
                                  <div
                                    className={`flex h-10 w-10 flex-none items-center justify-center rounded-lg ${iconColor(
                                      item.klass,
                                    )}`}
                                  >
                                    {item.klass === 'Users' && (
                                      <UserIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      />
                                    )}

                                    {item.klass === 'Accounts' && (
                                      <UserCircleIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      />
                                    )}

                                    {item.klass === 'Patients' && (
                                      <IdentificationIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      />
                                    )}

                                    {(item.klass === LOCS ||
                                      item.klass === ORGS ||
                                      item.klass === 'Products') && (
                                      <img
                                        src={
                                          item.imageUrl ||
                                          'https://cdn.retainerclub.com/images/defaults/default_partner_image.jpg'
                                        }
                                        alt={item.name}
                                        className="h-10 w-10"
                                      />
                                    )}
                                  </div>
                                  <div className="ml-4 flex-auto">
                                    <p
                                      className={`font-medium text-sm ${
                                        active
                                          ? 'text-rcprimary-400'
                                          : 'text-white/90'
                                      }`}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      className={`text-sm ${
                                        active
                                          ? 'text-white/40'
                                          : 'text-white/50'
                                      }`}
                                    >
                                      {getDescription(item)}
                                    </p>
                                  </div>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </Combobox.Options>
                )}

                {value !== '' && filteredItems.length === 0 && (
                  <div className="border-white/20 border-t px-6 py-14 text-center text-sm sm:px-14">
                    <FaceFrownIcon
                      className="mx-auto h-6 w-6 text-rcgray-500"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-rcgray-200">
                      {t('No results found')}
                    </p>
                    <p className="mt-2 text-rcgray-500">
                      {t(
                        "We couldn't find anything with that term. Please try again.",
                      )}
                    </p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Search.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Search;
