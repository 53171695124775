/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_ORDER } from '@retainerclub/customer-api';
import { useLocale } from '@retainerclub/hooks';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

function CheckoutSuccessSummary() {
  const { slug } = useParams();
  const [order, setOrder] = useState(null);
  const { locale } = useLocale();

  const { loading, error } = useQuery(GET_ORDER, {
    variables: { slug },
    skip: !slug,
    onCompleted: (data) => {
      setOrder(data.order);
    },
  });

  const subTotal = useMemo(
    () => order?.orderItems?.reduce((acc, item) => acc + item.price, 0),
    [order?.orderItems],
  );

  const shipping = order?.shippingAmount || 0;

  const taxes = 0;

  const total = useMemo(
    () => subTotal + shipping + taxes,
    [subTotal, shipping],
  );

  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} />;
  }

  return (
    <div className="mt-10 lg:mt-0">
      <h2 className="font-medium text-gray-900 text-lg">Order summary</h2>

      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <ul className="divide-y divide-gray-200">
          {order?.orderItems
            ? order.orderItems.map((orderItem) => (
                <li key={orderItem.id} className="flex px-4 py-6 sm:px-6">
                  <div className="shrink-0">
                    <img
                      src={orderItem.product.featuredImage}
                      alt={orderItem.product.name}
                      className="w-20 rounded-md"
                    />
                  </div>

                  <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                      <div className="min-w-0 flex-1">
                        <h4 className="font-medium text-base">
                          {orderItem.name}
                        </h4>
                        <p className="mt-1 text-gray-500 text-xs">
                          {orderItem.product.description}
                        </p>
                        <p className="mt-1 text-gray-500 text-xs">
                          {orderItem.patient ? (
                            <span>
                              Purchasing For:&nbsp;
                              <span className="font-bold">
                                {orderItem?.patient?.firstName}
                              </span>
                            </span>
                          ) : null}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                      <div className="text-sm">Qty: {orderItem.quantity}</div>

                      <p className="mt-1 ml-4 font-medium text-gray-900 text-sm">
                        {(orderItem.price / 100).toLocaleString(locale, {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </p>
                    </div>
                  </div>
                </li>
              ))
            : null}
        </ul>
        <dl className="space-y-6 border-gray-200 border-t px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between">
            <dt className="text-sm">Subtotal</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(subTotal / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="text-sm">Shipping</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(shipping / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="text-sm">Taxes</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(taxes / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between border-gray-200 border-t pt-6">
            <dt className="font-medium text-base">Total</dt>
            <dd className="font-medium text-base text-gray-900">
              {(total / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default CheckoutSuccessSummary;
