function initialValues() {
  return {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  };
}

export default initialValues;
