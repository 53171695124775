import PropTypes from 'prop-types';

function ShowSectionTitle({ title }) {
  return <h2 className="font-oswald text-black text-xl leading-6">{title}</h2>;
}
ShowSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ShowSectionTitle;
