import { EmailInput, Input, PhoneInput } from '@retainerclub/common';
import OnboardingFormAccountSameCheckbox from './OnboardingFormAccountSameCheckbox';

function OnboardingFormAccount() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
      <OnboardingFormAccountSameCheckbox />

      <Input
        className="sm:col-span-3"
        required
        name="userAttributes.firstName"
        label="Account Holder First Name"
      />
      <Input
        className="sm:col-span-3"
        required
        name="userAttributes.lastName"
        label="Account Holder Last Name"
      />
      <PhoneInput
        className="sm:col-span-3"
        required
        name="userAttributes.phone"
        label="Phone Number"
      />
      <EmailInput
        className="sm:col-span-3"
        label="Email"
        name="userAttributes.email"
        required
      />
      <Input
        className="sm:col-span-6"
        type="password"
        required
        name="userAttributes.password"
        label="Choose A Password"
      />
    </div>
  );
}

export default OnboardingFormAccount;
