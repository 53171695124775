/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useMutation } from '@apollo/client';
import { ACTION_S3_SIGN } from '@retainerclub/shared-api';
import axios from 'axios';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import FieldContainer from '../../FieldContainer/FieldContainer';
import DeleteImageButton from './DeleteImageButton';

function FileDropzone({
  name,
  label = null,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  accept = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/webp': ['.webp'],
  },
  acl = 'public-read',
  storagePath = 'images/logos',
  defaultImage = 'https://cdn.retainerclub.com/images/defaults/default.svg',
}) {
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { setValue, getValues, register } = useFormContext();

  const { ref } = register(name);

  const [signS3] = useMutation(ACTION_S3_SIGN);

  useEffect(() => () => URL.revokeObjectURL(preview), [preview]);

  function formatFilename(filename) {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  }

  async function uploadToS3(signedRequest, droppedFile) {
    setUploading(true);
    const options = {
      headers: {
        'Content-Type': droppedFile.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          setUploadProgress(100);
          setUploading(false);
        } else {
          setUploadProgress(percent);
        }
      },
    };

    await axios.put(signedRequest, droppedFile, options);
  }

  async function signAndSubmit(droppedFile) {
    const s3Key = `${storagePath}/${formatFilename(droppedFile.name)}`;

    try {
      const response = await signS3({
        variables: {
          input: {
            input: {
              originalFilename: droppedFile.name,
              filename: s3Key,
              acl,
              filetype: droppedFile.type,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      const { signedRequest, url } = response.data.actionSignS3.s3Response;

      await uploadToS3(signedRequest, droppedFile);

      setValue(name, url);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('signAndSubmit Error', err);
    }
  }

  async function onDrop(accepted) {
    const droppedFile = accepted[0];

    setPreview(URL.createObjectURL(droppedFile));
    await signAndSubmit(droppedFile);
  }

  function previewSrc() {
    if (preview) {
      return preview;
    }

    const currentValue = getValues(name);

    if (
      currentValue !== '' &&
      currentValue !== null &&
      currentValue !== undefined
    ) {
      return currentValue;
    }

    return defaultImage;
  }

  const { isDragActive, isDragReject, getRootProps, getInputProps, open } =
    useDropzone({
      accept,
      noClick: true,
      multiple: false,
      onDrop: (accepted) => onDrop(accepted),
      maxFiles: 1,
    });

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      file
    >
      <div
        className={`relative mt-2 flex justify-center rounded-lg border border-dashed p-4 shadow-sm ${
          isDragActive
            ? 'border-rcprimary-400/25 bg-rcprimary-400/10'
            : 'border-black/40'
        } ${isDragReject ? 'bg-red-500' : ''}`}
        {...getRootProps()}
      >
        {previewSrc() !== defaultImage ? (
          <div className="absolute top-2 right-6 size-5">
            <DeleteImageButton
              name={name}
              setPreview={setPreview}
              defaultImage={defaultImage}
            />
          </div>
        ) : null}
        <div className="w-full text-center">
          <div className="mx-auto size-28">
            <img
              src={previewSrc()}
              alt="upload preview"
              className="mx-auto max-h-24"
            />
          </div>
          <div className="mt-1 flex flex-col text-gray-600 text-sm leading-6">
            <label
              htmlFor={name}
              className="relative cursor-pointer rounded-md bg-white font-semibold text-rcprimary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-rcprimary-400 focus-within:ring-offset-2 hover:text-rcprimary-400"
            >
              <input ref={ref} {...getInputProps()} />
            </label>
            <p>
              {!uploading && isDragActive && 'Drop file here...'}
              {!uploading && !isDragActive && (
                <span className="cursor-pointer text-black/70">
                  Drop a file or
                  <br />
                  <button
                    type="button"
                    onClick={open}
                    className="text-rcprimary-400 text-sm hover:text-rcprimary-500"
                  >
                    click / tap here to select
                  </button>
                </span>
              )}
            </p>
            {uploading && (
              <div className="w-full">
                <progress
                  value={uploadProgress}
                  max="100"
                  className="h-1.5 w-full"
                >
                  {uploadProgress}
                </progress>
              </div>
            )}
          </div>
        </div>
      </div>
    </FieldContainer>
  );
}

FileDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  accept: PropTypes.array,
  acl: PropTypes.string,
  storagePath: PropTypes.string,
  defaultImage: PropTypes.string,
};

export default FileDropzone;
