import { useAccount } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import RetainerWizardNoBankSubscriptions from './RetainerWizardNoBankSubscriptions';

function RetainerWizardNoBank({ patient, handleCloseModal }) {
  const { setCurrentPatient } = useAccount();
  const navigate = useNavigate();

  function handleShop() {
    setCurrentPatient(patient);
    navigate('/shop');
  }

  return (
    <div className="isolate overflow-hidden">
      <div className="flow-root bg-gray-900 pt-8 pb-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="relative z-10">
            <h2 className="mx-auto max-w-4xl text-center font-bold text-5xl text-white tracking-tight">
              Right Retainer. Right Time
              <span className="align-super text-xl">&#8482;</span>
            </h2>
            <p className="mx-auto mt-4 max-w-2xl text-center text-lg text-white/60 leading-6">
              You have options! Choose to start an annual replacement plan with
              Member Pricing or purchase a single set of retainers by clicking
              the button below.
            </p>
            <div className="mt-4 flex items-center">
              <button
                type="button"
                onClick={handleShop}
                className="mx-auto rounded-full border border-rcprimary-400 px-2.5 py-1 text-rcprimary-400 text-sm hover:border-white/80 hover:text-white/80 focus-visible:outline-rcprimary-400 focus-visible:ring-1 focus-visible:ring-rcprimary-400/50"
              >
                Shop Other Options
              </button>
            </div>
          </div>
          <div className="lg:-mb-14 relative mx-auto mt-6 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <svg
              viewBox="0 0 1208 1024"
              aria-hidden="true"
              className="-bottom-48 -translate-x-1/2 lg:-top-48 absolute left-1/2 h-[64rem] translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:bottom-auto lg:translate-y-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                  <stop stopColor="#37b87b" />
                  <stop offset={1} stopColor="#d4f7e1" />
                </radialGradient>
              </defs>
            </svg>
            <div
              className="hidden lg:absolute lg:inset-x-px lg:top-4 lg:bottom-0 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
              aria-hidden="true"
            />

            <RetainerWizardNoBankSubscriptions
              patient={patient}
              handleCloseModal={handleCloseModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RetainerWizardNoBank.propTypes = {
  patient: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default RetainerWizardNoBank;
