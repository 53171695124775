import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

const PolicyContext = createContext();

export function PolicyProvider(props) {
  const { children } = props;

  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [policy, setPolicy] = useState('terms');

  const setPolicyAndOpenModal = useCallback((policyName) => {
    setPolicy(policyName);
    setPolicyModalOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      policy,
      setPolicy,
      policyModalOpen,
      setPolicyModalOpen,
      setPolicyAndOpenModal,
    }),
    [policy, policyModalOpen, setPolicyAndOpenModal],
  );

  return (
    <PolicyContext.Provider value={value}>{children}</PolicyContext.Provider>
  );
}

PolicyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function usePolicy() {
  return useContext(PolicyContext);
}
