function initialValues(props) {
  const { referral, partnerLocation } = props;

  const defaultAddress =
    referral?.account?.accountAddresses &&
    referral.account.accountAddresses.length > 0
      ? referral.account.accountAddresses.find(
          (address) => address.isDefault && address.isShipping,
        )
      : null;

  function filterPatient(patient) {
    const { __typename, slug, dob, includeEot, ...rest } = patient;
    return rest;
  }

  function filterUser(user) {
    const { __typename, id, slug, state, ...rest } = user;
    return rest;
  }

  return {
    id: referral?.id || null,
    partnerLocationId: partnerLocation?.id || null,
    userAttributes: referral?.userAttributes
      ? filterUser(referral.userAttributes)
      : {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          password: null,
          acceptTerms: false,
          acceptSms: false,
          acceptMarketing: false,
        },
    accountAddressesAttributes: [
      {
        address: defaultAddress?.address || null,
        address2: defaultAddress?.address2 || null,
        city: defaultAddress?.city || null,
        region: defaultAddress?.region || null,
        postalCode: defaultAddress?.postalCode || null,
        country: defaultAddress?.country || 'US',
        isShipping: true,
        isDefault: true,
      },
    ],
    patientAttributes: referral?.patientAttributes
      ? filterPatient(referral.patientAttributes)
      : {
          firstName: null,
          lastName: null,
          birthDay: 'Day',
          birthMonth: 'Month',
          birthYear: null,
        },
  };
}

export default initialValues;
