import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string(),
    phone: Yup.string(),
    language: Yup.string(),
    imageUrl: Yup.string().nullable(true),
  });
}

export default schema;
