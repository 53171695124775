import DashboardLayoutTitle from '../../layouts/DashboardLayout/DashboardLayoutTitle';
import OrdersHistory from './OrdersHistory/OrdersHistory';

function Orders() {
  return (
    <>
      <DashboardLayoutTitle
        heading="Orders & Shipments"
        title="My Orders"
        subtitle="View your order history and track your shipments."
      />
      <OrdersHistory />
    </>
  );
}

export default Orders;
