function PrivacyPolicy() {
  return (
    <>
      <p>
        Last Modified: <span className="italic">Januaray 1st 2022</span>
      </p>
      <h1 className="text-2xl font-bold">Privacy Policy</h1>
      <p>
        Retainer Club, Inc. &amp; Mouthguard Club, Inc. (&quot;Retainer
        Club&quot; or &quot;we&quot; or &quot;our&quot;) respects your privacy.
        This Privacy Policy describes how Retainer Club, Inc. and/or Mouthguard
        Club, Inc collects and uses your personal information when you visit or
        make a purchase from our website (the &quot;Site&quot;) or mobile
        applications.
      </p>

      <h3 className="text-lg font-bold">Personal information we collect</h3>

      <p>
        When you visit our Site or mobile applications, we automatically collect
        certain information about your device, including information about your
        web browser, IP address, time zone, and some of the cookies that are
        installed on your device. Additionally, as you browse the Site, we
        collect information about the individual web pages or products that you
        view, what websites or search terms referred you to the Site, and
        information about how you interact with the Site. We refer to this
        automatically-collected information as &quot;Device Information&quot;.
      </p>

      <h3 className="text-lg font-bold">
        We collect Device Information using the following technologies:
      </h3>

      <ul>
        <li>
          &quot;Cookies&quot; are data files that are placed on your device or
          computer and often include an anonymous unique identifier. For more
          information about cookies, and how to disable cookies, visit&nbsp;
          <a
            className="text-rcprimary-500 hover:text-rcprimary-600"
            href="http://www.allaboutcookies.org"
            target="_blank"
            rel="noreferrer"
          >
            http://www.allaboutcookies.org
          </a>
          .
        </li>

        <li>
          &quot;Log files&quot; track actions occurring on the Site, and collect
          data including your IP address, browser type, Internet service
          provider, referring/exit pages, and date/time stamps.
        </li>

        <li>
          &quot;Web beacons&quot;, &quot;tags&quot;, and &quot;pixels&quot; are
          electronic files used to record information about how you browse the
          Site.
        </li>
      </ul>

      <p>
        Additionally when you make a purchase or attempt to make a purchase
        through the Site, we collect certain information from you, including
        your name, billing address, shipping address, payment information
        including credit card numbers, email address, and phone number. We refer
        to this information as &quot;Order Information&quot;.
      </p>

      <p>
        When we talk about &quot;Personal Information&quot; in this Privacy
        Policy, we are talking both about Device Information and Order
        Information.
      </p>

      <h3 className="text-lg font-bold">
        How do we use your personal information?
      </h3>

      <p>
        We use the Order Information that we collect generally to fulfill any
        orders placed through the Site or our mobile applications (including
        processing your payment information, arranging for shipping, and
        providing you with invoices and/or order confirmations). Additionally,
        we use this Order Information to:
      </p>

      <ul>
        <li>Communicate with you;</li>

        <li>Screen our orders for potential risk or fraud; and</li>

        <li>
          When in line with the preferences you have shared with us, provide you
          with information or advertising relating to our products or services.
        </li>
      </ul>

      <p>
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns). We
        also use Device Information to provide you with information or
        advertising relating to our products or services.
      </p>

      <h3 className="text-lg font-bold">Sharing you personal Information</h3>

      <p>
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. For example, we use
        Google Analytics to help us understand how our customers use the Site --
        you can read more about how Google uses your Personal Information
        here:&nbsp;
        <a
          className="text-rcprimary-500 hover:text-rcprimary-600"
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.com/intl/en/policies/privacy/
        </a>
        . You can also opt-out of Google Analytics here:&nbsp;
        <a
          className="text-rcprimary-500 hover:text-rcprimary-600"
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>

      <p>
        We may also share your Personal Information to comply with applicable
        laws and regulations, to respond to a subpoena, search warrant or other
        lawful request for information we receive, or to otherwise protect our
        rights.
      </p>

      <h3 className="text-lg font-bold">Behavioral advertising</h3>

      <p>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative&#39;s
        (&quot;NAI&quot;) educational page at&nbsp;
        <a
          className="text-rcprimary-500 hover:text-rcprimary-600"
          href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
          target="_blank"
          rel="noreferrer"
        >
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
        </a>
        .
      </p>

      <p>
        We try to provide you with choices regarding how we use your personal
        data, particularly around marketing and advertising - we are a business,
        but you have the right to privacy. Like other businesses, we may target
        ads to you when you are on other websites and apps. We do this using a
        variety of delivery methods, and we use a range of advertising
        technologies like web beacons, pixels, ad tags, cookies, and mobile
        identifiers, as well as email and platform specific services offered by
        some sites and social networks, (for example Facebook&#39;s Custom
        Audience service). In many cases, the ads you see will be based on
        information we hold about you, or your previous use of our Platforms and
        communications. We may also use services such as those provided by
        MailChimp in order to contact you about items you have looked at or
        placed in a shopping cart if you do not proceed to complete a purchase.
        MailChimp and other such providers employ the use of cookies, unique
        identifiers, web beacons and similar tracking technologies to keep tabs
        on things like open rates and conversion rates. You may unsubscribe from
        such emails (which will include an unsubscribe link), or alternatively
        follow the steps in the &#45;opting out&#39; section below.
      </p>

      <p>You can opt out of targeted advertising by using the links below:</p>

      <ul>
        <li>
          Facebook:&nbsp;
          <a
            className="text-rcprimary-500 hover:text-rcprimary-600"
            href="https://www.facebook.com/settings/?tab=ads"
            target="_blank"
            rel="noreferrer"
          >
            https://www.facebook.com/settings/?tab=ads
          </a>
        </li>

        <li>
          Google:&nbsp;
          <a
            className="text-rcprimary-500 hover:text-rcprimary-600"
            href="https://www.google.com/settings/ads/anonymous"
            target="_blank"
            rel="noreferrer"
          >
            https://www.google.com/settings/ads/anonymous
          </a>
        </li>

        <li>
          Bing:&nbsp;
          <a
            className="text-rcprimary-500 hover:text-rcprimary-600"
            href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
            target="_blank"
            rel="noreferrer"
          >
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </a>
        </li>
      </ul>

      <p>
        Additionally, you can opt out of some of these services by visiting the
        Digital Advertising Alliance&#39;s opt-out portal at:&nbsp;
        <a
          className="text-rcprimary-500 hover:text-rcprimary-600"
          href="http://optout.aboutads.info/"
          target="_blank"
          rel="noreferrer"
        >
          http://optout.aboutads.info/
        </a>
        .
      </p>

      <h3 className="text-lg font-bold">Do not track</h3>

      <p>
        Please note that we do not alter our Site&#39;s data collection and use
        practices when we see a Do Not Track signal from your browser.
      </p>

      <h3 className="text-lg font-bold">Your rights</h3>

      <p>
        If you are a European resident, you have the right to access personal
        information we hold about you and to ask that your personal information
        be corrected, updated, or deleted. If you would like to exercise this
        right, please contact us through the contact information below.
      </p>

      <p>
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>

      <h3 className="text-lg font-bold">Data retention</h3>

      <p>
        When you place an order through the Site, we will maintain your Order
        Information for our records unless and until you ask us to delete this
        information.
      </p>

      <h3 className="text-lg font-bold">Changes</h3>

      <p>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </p>

      <h3 className="text-lg font-bold">Minors</h3>

      <p>
        The Site is not intended for individuals under the age of 18. Parents
        and legal guardians are permitted to provide information on behalf of
        children under the age of 18.
      </p>

      <p>
        We do provide goods and services to children under the age of 18 and
        parents and legal guardians are permitted to use our Site and mobile
        application to enter their children&#39;s information. We do not
        knowingly collect personal information directly from children under 18.
      </p>

      <h3 className="text-lg font-bold">Contact us</h3>

      <p>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by email
        at&nbsp;
        <a
          className="text-rcprimary-500 hover:text-rcprimary-600"
          href="mailto: compliance@retainerclub.com"
          target="_blank"
          rel="noreferrer"
        >
          compliance@retainerclub.com
        </a>
        &nbsp; or by mail using the details provided below:
      </p>

      <p>
        Retainer Club
        <br />
        Re: Privacy Compliance Officer
        <br />
        Retainer Club, Inc, 7895 E Acoma Dr #102, Scottsdale, AZ 85260, United
        States
      </p>
    </>
  );
}

export default PrivacyPolicy;
