import { DateInput, Input } from '@retainerclub/common';
import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

function OnboardingFormPatient({ setShow }) {
  const {
    patientAttributes: { firstName, lastName, birthDay, birthMonth, birthYear },
  } = useFormValues();

  useEffect(() => {
    if (firstName && lastName && birthDay && birthMonth && birthYear) {
      setShow(true);
    }
  }, [birthDay, birthMonth, birthYear, firstName, lastName, setShow]);

  return (
    <div className="col-span-12 grid grid-cols-6 gap-x-8 gap-y-4">
      <Input
        className="col-span-full sm:col-span-3"
        required
        name="patientAttributes.firstName"
        label="Patient First Name"
      />
      <Input
        className="col-span-full sm:col-span-3"
        required
        name="patientAttributes.lastName"
        label="Patient Last Name"
      />
      <DateInput
        dayName="patientAttributes.birthDay"
        monthName="patientAttributes.birthMonth"
        yearName="patientAttributes.birthYear"
        required
        label="Patient Date of Birth"
        wrapperClass="col-span-full sm:col-span-3"
      />
    </div>
  );
}

OnboardingFormPatient.propTypes = {
  setShow: PropTypes.func.isRequired,
};

export default OnboardingFormPatient;
