function initialValues(props) {
  const { currentUser } = props;

  return {
    id: currentUser?.username ? currentUser.username : '',
    firstName: currentUser?.firstName ? currentUser.firstName : '',
    lastName: currentUser?.lastName ? currentUser.lastName : '',
    phone: currentUser?.phone ? currentUser.phone : '',
    language: currentUser?.language ? currentUser.language : '',
    imageUrl: currentUser?.imageUrl ? currentUser.imageUrl : null,
  };
}

export default initialValues;
