import { CustomerCard } from '@retainerclub/customer-shared';

function OrdersHistoryNoOrders() {
  return (
    <CustomerCard title="Order History">
      <div>
        <h3>
          Sorry, you haven&apos;t placed any orders yet.
          <br />
        </h3>
      </div>
    </CustomerCard>
  );
}

export default OrdersHistoryNoOrders;
