import { ErrorMessage } from '@retainerclub/common';
import { useToast } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function OnboardingFormErrors({ mutationErrors = null, error = null }) {
  const { setToast } = useToast();
  const {
    formState: { isSubmitting, errors },
  } = useFormContext();

  useEffect(() => {
    if (isSubmitting && errors && Object.keys(errors).length > 0) {
      setToast({
        show: true,
        title: 'Error',
        message: 'Please fix the errors below and try again.',
        success: false,
      });
    }
  }, [errors, isSubmitting, setToast]);

  return mutationErrors || error ? (
    <div className="flex flex-col gap-4">
      {mutationErrors && (
        <div>
          <ErrorMessage errorMessages={mutationErrors} />
        </div>
      )}

      {error && (
        <div>
          <ErrorMessage errorMessages={[error]} />
        </div>
      )}
    </div>
  ) : null;
}

OnboardingFormErrors.propTypes = {
  mutationErrors: PropTypes.object,
  error: PropTypes.object,
};

export default OnboardingFormErrors;
