import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { CREATE_ONBOARDING } from '@retainerclub/customer-api';
import { useAuth, useReferral } from '@retainerclub/hooks';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import OnboardingFormInner from './OnboardingFormInner';
import { initialValues, schema } from './helpers';

function OnboardingForm() {
  const { referral, partnerLocation, rError, pError } = useReferral();
  const { login } = useAuth();
  const navigate = useNavigate();

  const [mutationErrors, setMutationErrors] = useState(null);

  const [create, { error, loading }] = useMutation(CREATE_ONBOARDING);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, isLoading },
    formState,
    control,
    getValues,
    setValue,
    getFieldState,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ referral, partnerLocation }),
    mode: 'onBlur',
  });

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: uuidv4(),
          },
        },
      };

      const { data } = await create(params);

      if (data?.errors?.length > 0) {
        setMutationErrors(data.errors);
      } else {
        await login({
          phone: values.userAttributes.phone,
          password: values.userAttributes.password,
          skipNavigate: true,
        });

        navigate('/', {});
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
      getFieldState={getFieldState}
    >
      <form onSubmit={handleSubmit(submitRecord)} className="" noValidate>
        <OnboardingFormInner
          mutationErrors={mutationErrors}
          error={error}
          isSubmitting={isSubmitting}
          isValid={isValid}
          isLoading={isLoading}
          rError={rError}
          pError={pError}
          loading={loading}
        />
      </form>
    </FormProvider>
  );
}

export default OnboardingForm;
