import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

function DetailsSection(props) {
  const { name, children } = props;

  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <h3>
            <Disclosure.Button className="group relative flex w-full items-center justify-between py-4 text-left">
              <span className="font-medium text-black/90 text-sm ui-open:text-rcprimary-500">
                {name}
              </span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon
                    className="block h-6 w-6 text-rcprimary-400 group-hover:text-rcprimary-500"
                    aria-hidden="true"
                  />
                ) : (
                  <PlusIcon
                    className="block h-6 w-6 text-black/60 group-hover:text-black"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel as="div" className="prose prose-sm pb-4">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

DetailsSection.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

export default DetailsSection;
