import * as Yup from 'yup';

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    address: Yup.string().nullable(true).required('address is required'),
    address2: Yup.string().nullable(true),
    city: Yup.string().required('city is required'),
    region: Yup.string().required('region / state is required'),
    postalCode: Yup.string().required('postal code is required'),
    country: Yup.string(),
    accountId: Yup.string().required('account is required'),
    isShipping: Yup.boolean().required('is shipping is required'),
    isDefault: Yup.boolean(),
  });
}

export default schema;
