import { AppInner, ErrorPage, Profile } from '@retainerclub/common';
import {
  AccountProvider,
  CartProvider,
  ReferralProvider,
} from '@retainerclub/hooks';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout/AuthLayout';
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import MainLayout from './layouts/MainLayout/MainLayout';
import RequireAuth from './routing/RequireAuth';
import Addresses from './views/Addresses/Addresses';
import {
  CompletePassword,
  ForgotPassword,
  ResetPassword,
  SignIn,
} from './views/Auth';
import Checkout from './views/Checkout/Checkout';
import CheckoutSuccess from './views/Checkout/CheckoutSuccess/CheckoutSuccess';
import Dashboard from './views/Dashboard/Dashboard';
import Family from './views/Family/Family';
import Onboarding from './views/Onboarding/Onboarding';
import OnboardingForm from './views/Onboarding/OnboardingForm/OnboardingForm';
import Orders from './views/Orders/Orders';
import Shop from './views/Shop/Shop';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppInner />} errorElement={<ErrorPage />}>
        <Route element={<AccountProvider />}>
          <Route element={<CartProvider />}>
            <Route element={<ReferralProvider />}>
              <Route element={<MainLayout />}>
                <Route element={<RequireAuth />}>
                  <Route element={<Shop />} path="/shop" />
                  <Route element={<DashboardLayout />}>
                    <Route element={<Dashboard />} path="/" />

                    <Route element={<Profile />} path="/profile" />
                    <Route element={<Addresses />} path="/addresses" />
                    <Route element={<Family />} path="/my-family" />
                    <Route element={<Orders />} path="/my-orders" />
                    <Route element={<Navigate to="/" replace />} path="*" />
                  </Route>
                </Route>

                <Route element={<Outlet />}>
                  <Route element={<AuthLayout />}>
                    <Route element={<SignIn />} path="/signin" />
                    <Route
                      element={<ForgotPassword />}
                      path="/forgot-password"
                    />
                    <Route element={<ResetPassword />} path="/reset-password" />
                    <Route
                      element={<CompletePassword />}
                      path="/new-password"
                    />
                  </Route>
                  <Route element={<Onboarding />} path="/onboarding">
                    <Route
                      element={<Navigate to="get-started" replace />}
                      index
                    />
                    <Route element={<OnboardingForm />} path="get-started" />
                    <Route element={<OnboardingForm />} path="create-account" />
                  </Route>
                  <Route element={<Checkout />} path="/checkout" />
                  <Route
                    element={<CheckoutSuccess />}
                    path="/checkout/success/:slug"
                  />
                </Route>

                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
