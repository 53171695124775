function initialValues({ account = {}, user = {}, cartId }) {
  const { accountAddresses = [] } = account;

  const defaultShippingAddress =
    accountAddresses && accountAddresses?.length > 0
      ? accountAddresses.find(
          (address) => address.isDefault && address.isShipping,
        )
      : null;

  const defaultBillingAddress =
    accountAddresses && accountAddresses?.length > 0
      ? accountAddresses.find(
          (address) => address.isDefault && address.isBilling,
        )
      : null;

  return {
    cartId,
    deliveryMethod: 'standard',
    creditCard: {
      nameOnCard: `${user?.firstName} ${user?.lastName}`,
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    },
    billingAddress: {
      id: defaultBillingAddress?.id || null,
      address: defaultBillingAddress?.address || '',
      address2: defaultBillingAddress?.address2 || '',
      city: defaultBillingAddress?.city || '',
      country: defaultBillingAddress?.country || 'US',
      region: defaultBillingAddress?.region || '',
      postalCode: defaultBillingAddress?.postalCode || '',
    },
    shippingAddress: {
      id: defaultShippingAddress?.id || null,
      address: defaultShippingAddress?.address || '',
      address2: defaultShippingAddress?.address2 || '',
      city: defaultShippingAddress?.city || '',
      country: defaultShippingAddress?.country || 'US',
      region: defaultShippingAddress?.region || '',
      postalCode: defaultShippingAddress?.postalCode || '',
    },
  };
}

export default initialValues;
