import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useCart, useLocale } from '@retainerclub/hooks';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const deliveryMethods = [
  {
    id: 'standard',
    title: 'Standard',
    turnaround: '3-5 USPS 1st Class',
    price: 0,
  },
  {
    id: 'express',
    title: 'Express',
    turnaround: '2 Day FedEx',
    price: 2000,
  },
];

function CheckoutFormDelivery() {
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0],
  );
  const { locale } = useLocale();

  const { updateCart, cartId } = useCart();

  const { setValue } = useFormContext();

  function handleDeliveryMethodChange(deliveryMethod) {
    const newDeliveryMethod = deliveryMethods.find(
      (d) => d.id === deliveryMethod,
    );

    setSelectedDeliveryMethod(newDeliveryMethod);

    const variables = {
      id: cartId,
      shippingAmount: newDeliveryMethod.price,
    };

    updateCart(variables);
    setValue('deliveryMethod', newDeliveryMethod.id);
  }

  return (
    <div className="mt-4 border-rcgray-300 border-t pt-4">
      <RadioGroup
        value={selectedDeliveryMethod.id}
        onChange={(deliveryMethod) =>
          handleDeliveryMethodChange(deliveryMethod)
        }
      >
        <RadioGroup.Label className="font-medium text-gray-900 text-lg">
          Delivery method
        </RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {deliveryMethods.map((deliveryMethod) => (
            <RadioGroup.Option
              key={deliveryMethod.id}
              value={deliveryMethod.id}
              checked={selectedDeliveryMethod.id === deliveryMethod.id}
              className={({ checked, active }) =>
                `${checked ? 'border-transparent ' : 'border-gray-300 '} ${active ? 'ring-2 ring-rcprimary-500 ' : ''} relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block font-medium text-gray-900 text-sm"
                      >
                        {deliveryMethod.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-gray-500 text-sm"
                      >
                        {deliveryMethod.turnaround}
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        as="span"
                        className="mt-6 font-medium text-gray-900 text-sm"
                      >
                        {deliveryMethod.price === 0
                          ? 'FREE'
                          : `${(deliveryMethod.price / 100).toLocaleString(
                              locale,
                              {
                                style: 'currency',
                                currency: 'USD',
                              },
                            )}`}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  {checked ? (
                    <CheckCircleIcon
                      className="size-5 text-rcprimary-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <span
                    className={`${active ? 'border' : 'border-2'} ${checked ? 'border-rcprimary-500' : 'border-transparent'} -inset-px pointer-events-none absolute rounded-lg`}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

export default CheckoutFormDelivery;
